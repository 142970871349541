import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal } from '@common/components/modal';
import { ImageItemUser } from '@common/components/image-item/user';
import Tabs from '@common/components/tabs';
import AsyncList from '@common/components/list/async';

import { Api } from '@common/services/api';
import { useAppSelector } from '@common/hooks/redux';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';

import type { MessageEmojiReaction, User } from '@common/types/objects';
import { Pagination } from '../list';


export type EmojiReactionsUserListProps = {
  show: boolean;
  onSetVisibility: (isVisible: boolean) => void;
  messageId: string;
  conversationId: string;
  emojiReactions: MessageEmojiReaction[];
  children: JSX.Element;
};

type EmojiReactionsUserListApiResponse = {
  data: User[];
  meta: {
    pagination: Pagination;
  };
};

export const EmojiReactionsUserList = ({
  show,
  onSetVisibility,
  messageId,
  conversationId,
  emojiReactions = [],
  children,
}: EmojiReactionsUserListProps) => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentOrgId);
  const [modalWrapper, setModalWrapper] = useState<HTMLDivElement>();

  const handleOnShow = useCallback(() => {
    onSetVisibility(true);
    if (document) {
      setModalWrapper(document.getElementsByClassName('EmojiReactionsUserListModal')[0] as HTMLDivElement);
    }
  }, [setModalWrapper, onSetVisibility]);

  const onFetch = useCallback((nextCursor: string | null, filter?: { reactionShortName: string }) => {
    if (filter) {
      const query = Api.utils.toQuery({
        short_name: filter.reactionShortName,
        cursor: nextCursor || true,
      });
      return Api.get<EmojiReactionsUserListApiResponse>(
        `/v3/organisations/${orgId}/conversations/${conversationId}/messages/${messageId}/reactions/users?${query}`,
      );
    }
    // For type checking
    return Promise.resolve({ data: [], meta: { pagination: {} } });
  }, [orgId, conversationId, messageId]);

  return (
    <Modal
      list
      show={show}
      onShow={handleOnShow}
      onClose={() => onSetVisibility(false)}
      title={t('chat:reactions')}
      className="EmojiReactionsUserListModal"
      content={(
        <Tabs variant="full_width" containerClassName="EmojiReactionsUserList__Tabs">
          {emojiReactions.map((emoji) => (
            <Tabs.Item
              key={emoji.short_name}
              title={(
                <>
                  <span>{emoji.character}</span>
                  <span>{emoji.count}</span>
                </>
              )}
            >
              <AsyncList
                containerClassName="EmojiReactionsUserList__AsyncList"
                data={{
                  cache: { nextCursor: emoji.usersNextCursor },
                  useCursor: true,
                  filter: { reactionShortName: emoji.short_name },
                  onFetch,
                }}
                items={emoji?.users}
                renderRow={ImageItemUser}
                disableInitialFetch={(emoji?.users?.length || 0) > 0}
                scrollContainer={modalWrapper}
              />
            </Tabs.Item>
          ))}
        </Tabs>
      )}
    >
      {children}
    </Modal>
  );
};
