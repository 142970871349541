import React from 'react';

import { combineClassNames } from '@utils/combineClassNames';
import type { EmojiReaction } from '@common/types/objects';

type EmojiReactionsProps = {
  className?: string;
  reactions: EmojiReaction[];
  onSetReaction: (emoji: EmojiReaction, isSelected: boolean) => void;
};

export const EmojiReactions = ({ className, reactions = [], onSetReaction }: EmojiReactionsProps) => (
  <div className={combineClassNames('EmojiReactions', className)}>
    {reactions.map((reaction) => (
      <button
        className={combineClassNames('EmojiReactionButton', {
          'EmojiReactionButton--selected': reaction.is_selected_by_user,
        })}
        onClick={() => onSetReaction(reaction, !reaction.is_selected_by_user)}
      >
        <span className="EmojiReactionButton__Emoji">{reaction.character}</span>
        <span className="EmojiReactionButton__Count">{reaction.count}</span>
      </button>
    ))}
  </div>
);
