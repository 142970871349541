import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Dropdown from '@common/components/dropdown';
import Icon from '@common/components/icon';
import Confirm from '@common/components/confirm-button';
import UserStatusPreview from '@common/components/user-status/user-status-preview';
import Participant from '../participant';

// Selectors
import { useAppSelector } from '@common/hooks';
import * as userSelector from '../../../core/selectors/logged-user';

// Types
import { User, UserStatus } from '@common/types/objects';
import { FullConversation } from '@modules/chat/types/objects';

interface ParticipantActionsProps {
  item: User;
  conversation: FullConversation;
  usersStatus: Record<string, UserStatus | null>;
  onEditGroupAdmin: (conversationId: string, user: User, add: boolean) => void;
  onOpenProfile?: (userId: string) => void;
  onRemove?: (participant: User) => void;
}

const ParticipantActions = ({
  item, conversation, usersStatus, onEditGroupAdmin, onRemove, onOpenProfile,
}: ParticipantActionsProps) => {
  const { t } = useTranslation();
  const loggedUser = useAppSelector(userSelector.selected);
  return (
    <Participant key={item.id} item={item} loggedUser={loggedUser}>
      {usersStatus[item.id] ? <UserStatusPreview status={usersStatus[item.id]!} /> : null}
      {item.id !== loggedUser.id && (
        <Dropdown
          alignRight
          id={`participant-${item.id}-actions`}
          toggle={(<Icon type="more_vert" />)}
        >
          {onOpenProfile && (
            <Dropdown.Item onClick={() => onOpenProfile(item.id)}>
              {t('chat:conversation_participant_show_profile')}
            </Dropdown.Item>
          )}
          {conversation.is_admin && (
            <>
              <Dropdown.Divider />
              {conversation.admin_ids.includes(item.id)
                ? (
                  <Confirm
                    key="remove"
                    title={t('chat:conversation_confirm_remove_admin', { fullName: item.full_name })}
                    onConfirm={() => onEditGroupAdmin(conversation.id, item, false)}
                  >
                    <Dropdown.Item>
                      {t('chat:conversation_remove_admin')}
                    </Dropdown.Item>
                  </Confirm>
                )
                : (
                  <Confirm
                    key="add"
                    title={t('chat:conversation_confirm_add_admin')}
                    description={t('chat:conversation_confirm_add_admin_description', { fullName: item.full_name })}
                    onConfirm={() => onEditGroupAdmin(conversation.id, item, true)}
                  >
                    <Dropdown.Item>
                      {t('chat:conversation_add_admin')}
                    </Dropdown.Item>
                  </Confirm>
                )}
              {onRemove && (
                <Confirm
                  title={t('chat:conversation_confirm_remove_participant', { fullName: item.full_name })}
                  onConfirm={() => onRemove(item)}
                >
                  <Dropdown.Item danger>
                    {t('chat:conversation_remove_participant')}
                  </Dropdown.Item>
                </Confirm>
              )}
            </>
          )}
        </Dropdown>
      )}
    </Participant>
  );
};

export default ParticipantActions;
