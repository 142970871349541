import React, {
  Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { AlertService } from '@services/alert';
import Spinner from '@common/components/spinner';
import Icon from '@common/components/icon';
import { Button } from '@common/components/button';
import PostMessageForm, { PostMessageFormData } from '@modules/chat/forms/post-message';
import ConversationsByDay from '@modules/chat/components/conversations-by-day';
import type { Pagination } from '@common/components/list';

// Api
import { fetchMessages } from '@modules/chat/api/fetch-messages';
import { deleteMessage } from '@modules/chat/api/delete-message';
import { setMessageEmoji } from '@modules/chat/api/set-message-emoji';
import { postMessage } from '@modules/chat/api/post-message';
import { getAllowedEmojis } from '@modules/chat/api/get-allowed-emojis';

// Hooks
import { useAppSelector } from '@common/hooks/redux/use-app-selector';
import { usePusherMessagesListener } from '@modules/chat/hooks/use-pusher-messages-listener';

// Utils
import { formatMessages, parseMessages } from '@modules/chat/utils/messages';

// Selectors
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';

// Types
import type { FullConversation, PrivateMessage } from '@modules/chat/types/objects';
import type { AllowedEmoji, User, UserStatus } from '@common/types/objects';

interface MessagesProps {
  conversation: FullConversation;
  messages: PrivateMessage[] | null;
  usersStatus: Record<string, UserStatus | null>;
  onNewMessage: (id: string, message: PrivateMessage) => void;
  setMessages: Dispatch<SetStateAction<PrivateMessage[] | null>>;
  onUpdateLastMessage: (
    id: string, propName: keyof PrivateMessage, value: PrivateMessage[keyof PrivateMessage], messageId: string,
  ) => void;
  onEditParticipants: (
    conversationId: string, activities: PrivateMessage | PrivateMessage[], participants: User | User[],
  ) => void;
}

const Messages = ({
  conversation, usersStatus, messages,
  onNewMessage, setMessages, onEditParticipants, onUpdateLastMessage,
}: MessagesProps) => {
  const { t } = useTranslation();

  const listRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const orgId = useAppSelector(getCurrentOrgId);

  const [replyToMessage, setReplyToMessage] = useState<PrivateMessage | undefined>(undefined);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [allowedEmojis, setAllowedEmojis] = useState<AllowedEmoji[]>([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingMore, setIsFetchingMore] = useState(false);

  const users = useMemo(() => conversation.participants.reduce((acc, participant) => ({
    ...acc,
    [participant.id]: participant,
  }), {}), [conversation.participants]);

  const scrollToBottom = useCallback(() => {
    if (listRef.current) listRef.current.scrollTop = listRef.current.scrollHeight;
  }, [listRef]);

  useEffect(() => {
    const load = async () => {
      setIsFetching(true);
      const response = await fetchMessages(orgId, conversation.id, true);
      setMessages(parseMessages(response.data, response.meta.related));
      setPagination(response.meta.pagination);
      setTimeout(() => inputRef.current?.focus(), 0);
      setTimeout(scrollToBottom, 0);
      setIsFetching(false);

      const { data } = await getAllowedEmojis();
      setAllowedEmojis(data);
    };
    load();
  }, [orgId, conversation.id, setMessages, scrollToBottom]);

  const updateMessage = useCallback((
    messageId: string, propName: keyof PrivateMessage, value: PrivateMessage[keyof PrivateMessage], conversationId?: string,
  ) => {
    setMessages((prev) => prev?.map((message) => {
      if (message.id === messageId) {
        return { ...message, [propName]: value };
      }
      return message;
    }) || null);
    if (conversationId) {
      onUpdateLastMessage(conversationId, propName, value, messageId);
    }
  }, [messages, setMessages, onUpdateLastMessage]);

  useEffect(() => {
    if (conversation.last_message?.id && conversation.last_message?.seen === false) {
      updateMessage(conversation.last_message?.id, 'seen', true, conversation.id);
    }
  }, [conversation.last_message, conversation.id, updateMessage]);

  const handleShowMore = useCallback(async () => {
    setIsFetchingMore(true);
    const oldHeight = contentRef.current?.offsetHeight || 0;
    const response = await fetchMessages(orgId, conversation.id, pagination?.next_cursor || true);
    setMessages((prev) => [...parseMessages(response.data, response.meta.related), ...(prev || [])]);
    setPagination(response.meta.pagination);
    if (listRef.current && contentRef.current && oldHeight) {
      listRef.current.scrollTop = listRef.current.scrollTop + contentRef.current.offsetHeight - oldHeight;
    }
    setIsFetchingMore(false);
  }, [pagination?.next_cursor, orgId, conversation.id, setMessages, setIsFetchingMore, setPagination]);

  const handleAddMessage = useCallback(async (message: PostMessageFormData) => {
    const newMessages = await postMessage(orgId, conversation.id, message);
    const parsedMessage = parseMessages(newMessages, { users: Object.values(users) })[0];
    onNewMessage(conversation.id, parsedMessage);
    setReplyToMessage(undefined);
    scrollToBottom();
    setTimeout(() => inputRef.current?.focus(), 0);
  }, [orgId, conversation.id, users, onNewMessage, scrollToBottom, setReplyToMessage]);

  const handleReplyToMessage = useCallback((messageId?: string) => {
    setReplyToMessage(messages?.find((message) => message.id === messageId));
    inputRef.current?.focus();
  }, [messages, inputRef, setReplyToMessage]);

  const handleRemoveMessage = useCallback(async (messageId: string) => {
    const response = await deleteMessage(orgId, conversation.id, messageId);
    if (response.success) updateMessage(messageId, 'deleted_at', new Date().toISOString(), conversation.id);
  }, [orgId, conversation.id, updateMessage]);

  const handleSetEmojiReaction = useCallback(async (messageId: string, emoji: AllowedEmoji, isSelected: boolean) => {
    try {
      const response = await setMessageEmoji(orgId, conversation.id, messageId, emoji, !isSelected);
      updateMessage(messageId, 'reactions', response.data.reactions);
    } catch (e: any) {
      if (e && typeof e === 'object' && 'status_code' in e) {
        AlertService.forStatus(e.status_code, {
          warning: t('chat:setting_emoji_reaction_warning'),
          error: t('chat:setting_emoji_reaction_error'),
        });
      }
    }
  }, [orgId, conversation.id, updateMessage]);

  const handlePusherNewMessage = useCallback((
    _: string, id?: string, message?: PrivateMessage, markAsSeen: boolean = false,
  ) => {
    if (!message || !id) return;
    onNewMessage(id, {
      ...message,
      seen: markAsSeen ? true : message.seen,
    });
    setTimeout(scrollToBottom, 0);
  }, [onNewMessage, scrollToBottom]);

  // Socket listener
  usePusherMessagesListener({
    handlePusherNewMessage,
    updateMessage,
    messages,
    users,
  });

  const days = useMemo(() => formatMessages(messages || []), [messages]);

  return (
    <>
      <div
        ref={listRef}
        className="Conversation__Content fs-exclude"
      >
        {isFetching && <Spinner centered size="large" />}
        {!isFetching && messages?.length === 0 && (
          <div className="Conversation__Content__Placeholder">
            <div><Icon type="chat__filled" /></div>
            {t('chat:conversation_messages_placeholder')}
          </div>
        )}
        {!isFetching && (
          <div ref={contentRef}>
            {pagination?.next_cursor && (
              <Button
                isLoading={isFetchingMore}
                className="Conversation__LoadMore"
                onClick={handleShowMore}
              >
                {t('chat:conversation_load_older_messages')}
              </Button>
            )}
            {days.map(({ date, sections }) => (
              <ConversationsByDay
                date={date}
                sections={sections}
                conversation={conversation}
                allowedEmojis={allowedEmojis}
                usersStatus={usersStatus}
                onReply={handleReplyToMessage}
                onRemove={handleRemoveMessage}
                onSetEmojiReaction={handleSetEmojiReaction}
                onEditParticipants={onEditParticipants}
              />
            ))}
          </div>
        )}
      </div>
      {conversation.has_left ? (
        <div className="PostPrivateMessage PostPrivateMessage--disabled">
          {t('chat:conversation_has_left_message')}
        </div>
        ) : (
          <PostMessageForm
            inputRef={inputRef}
            form={`post-message/${conversation.id}`}
            replyTo={replyToMessage}
            handleReplyToMessage={handleReplyToMessage}
            onAddMessage={handleAddMessage}
          />
        )}
    </>
  );
};

export default Messages;
