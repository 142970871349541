import React, { Fragment } from 'react';

// Components
import ProfileImage from '@common/components/profile-image';
import MessageItem from '@modules/chat/components/message';
import ActivityItem from '@modules/chat/components/activity';

// Utils
import { createDateStamp } from '@common/utils/create-timestamp';

// Selectors
import { useAppSelector } from '@common/hooks/redux';
import * as userSelector from '../../../core/selectors/logged-user';

// Types
import { EConversationTypes, EMessageTypes } from '@modules/chat/definitions';
import { AllowedEmoji, User, UserStatus } from '@common/types/objects';
import { PrivateMessage, FullConversation } from '@modules/chat/types/objects';

interface ConversationsByDayProps {
  date: string;
  sections: { user?: User; messages: PrivateMessage[] }[];
  conversation: FullConversation;
  allowedEmojis: AllowedEmoji[];
  usersStatus: Record<string, UserStatus | null>;
  onReply: (messageId: string) => void;
  onRemove: (messageId: string) => void;
  onSetEmojiReaction: (messageId: string, emoji: AllowedEmoji, isSelected: boolean) => void;
  onEditParticipants: (
    conversationId: string, activities: PrivateMessage | PrivateMessage[], participants: User | User[],
  ) => void;
}

const ConversationsByDay = ({
  date, sections, conversation, allowedEmojis, usersStatus,
  onReply, onRemove, onSetEmojiReaction, onEditParticipants,
}: ConversationsByDayProps) => {
  const loggedUser = useAppSelector(userSelector.selected);
  const isGroup = conversation.conversation_type === EConversationTypes.GROUP;
  return (
    <Fragment key={date}>
      <div className="Conversation__DayHeader">
        <span>{createDateStamp(date)}</span>
      </div>
      {sections.map(({ user, messages }) => (
        <div key={messages[0]?.id} className="Conversation__UserSection">
          <div className="Conversation__UserSection__Content">
            {user && user.id !== loggedUser.id && isGroup && (
              <div className="Conversation__UserSection__User">
                <ProfileImage
                  size={36}
                  user={user}
                  formattedStatus={usersStatus[user.id]}
                />
              </div>
            )}
            <div className="Conversation__UserSection__Messages">
              {messages.map((item, index) => {
                switch (item.type) {
                  case EMessageTypes.MESSAGE:
                    return (
                      <MessageItem
                        key={item.id}
                        index={index}
                        isGroup={isGroup}
                        item={item}
                        loggedUser={loggedUser}
                        conversationId={conversation.id}
                        hasLeft={conversation.has_left}
                        allowedEmojis={allowedEmojis}
                        onSetEmojiReaction={onSetEmojiReaction}
                        onRemove={onRemove}
                        onReply={onReply}
                      />
                    );
                  case EMessageTypes.ACTIVITY:
                    return (
                      <ActivityItem
                        key={item.id}
                        item={item}
                        conversation={conversation}
                        usersStatus={usersStatus}
                        onEditParticipants={onEditParticipants}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        </div>
      ))}
    </Fragment>
  );
};

export default ConversationsByDay;
