import { useCallback, useEffect } from 'react';

// Services
import SocketService from '@common/services/socket';

// Api
import fetchConversation from '../api/fetch-conversation';

// Utils
import { parseMessage } from '../utils/messages';

// Types
import type { APIResponseFetchMessages } from '../api/fetch-messages';
import type { APIResponsePrivateMessage, FullConversation, PrivateMessage } from '../types/objects';
import { EConversationActivityTypes } from '../definitions';
import { ESocketEvents } from '@modules/core/definitions';
import { parseConversation } from '../utils/conversations';

type UsePusherConversationListenerProps = {
  orgId: string;
  loggedUserId: string;
  handleNewMessage: (conversationId: string, message: PrivateMessage) => void;
  handleNewConversation: (conversation: FullConversation) => void;
};

type AddActivityPayload = {
  data: APIResponsePrivateMessage;
  meta: APIResponseFetchMessages['meta'];
};

export const usePusherConversationListener = ({
  orgId, loggedUserId, handleNewMessage, handleNewConversation,
}: UsePusherConversationListenerProps) => {
  const onAddNewConversationHandler = useCallback(async (payload: AddActivityPayload) => {
    if (payload.data.parent_id) {
      const { data, meta } = await fetchConversation(orgId, payload.data.parent_id);
      handleNewConversation(parseConversation(data, meta.related, loggedUserId));
    }
  }, [handleNewConversation, orgId, loggedUserId]);

  const onAddActivityHandler = useCallback((payload: AddActivityPayload) => {
    switch (payload.data.source.activity_type) {
      case EConversationActivityTypes.USER_ADDED:
      case EConversationActivityTypes.USER_REMOVED:
      case EConversationActivityTypes.USER_LEFT:
      case EConversationActivityTypes.CHANGED_NAME:
        if (payload.data.parent_id) {
          handleNewMessage(payload.data.parent_id, parseMessage({
            ...payload.data,
            seen: false,
          }, payload.meta.related));
        }
        break;
      case EConversationActivityTypes.CONVERSATION_CREATED:
        onAddNewConversationHandler(payload);
        break;
      default:
        break;
    }
  }, [handleNewMessage, onAddNewConversationHandler]);

  useEffect(() => {
    SocketService.registerEvent('me', ESocketEvents.CONVERSATION_ACTIVITY_CREATED, onAddActivityHandler);
  }, [onAddActivityHandler]);
};
