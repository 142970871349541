import React, {
  PropsWithChildren, useCallback, useEffect, useRef, useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Components
import * as Alert from '@common/services/alert';
import Modal from '@common/components/modal';
import { Button } from '@common/components/button';
import { Group, Row } from '@common/components/form';
import { TextInput } from '@common/components/form/inputs/text';
import List from '@common/components/list';
import Icon from '@common/components/icon';
import SectionHeader from '@common/components/section-header';

// Chat components
import Participant from '@modules/chat/components/participant';
import ParticipantsList from '@modules/chat/components/participants-list';

// Selectors
import * as userSelector from '../../../core/selectors/logged-user';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';

// Hooks
import { useAppSelector } from '@common/hooks/redux';
import { useToggleParticipants } from '@modules/chat/hooks/use-toggle-participants';

// Utils
import { parseNewConversation } from '@modules/chat/utils/conversations';

// Types
import { User } from '@common/types/objects';
import { EConversationTypes } from '@modules/chat/definitions';
import { createConversation } from '@modules/chat/api/create-conversation';
import { FullConversation } from '@modules/chat/types/objects';

interface NewConversationFormProps extends PropsWithChildren<{}> {
  show: boolean;
  basePath: string;
  onShow: () => void;
  onNewConversation: (conversation: FullConversation) => void;
  onClose?: () => void;
}

const NewConversationForm = ({
  show, basePath, children, onShow, onClose, onNewConversation,
}: NewConversationFormProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);

  const loggedUser = useAppSelector(userSelector.selected);
  const orgId = useAppSelector(getCurrentOrgId);

  const [step, setStep] = useState(0);
  const [isGroup, setIsGroup] = useState(false);
  const [groupParticipants, setGroupParticipants] = useState<User[]>([]);
  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    if (step === 1) ref.current?.focus();
  }, [step]);

  const handleToggleGroupParticipant = useToggleParticipants(groupParticipants, setGroupParticipants);

  const handleRemoveGroupParticipant = useCallback((user: User) => {
    setGroupParticipants(groupParticipants.filter((u) => u.id !== user.id));
  }, [groupParticipants]);

  const handleReset = useCallback(() => {
    setStep(0);
    setIsGroup(false);
    setGroupParticipants([]);
    setName('');
    setSubmitting(false);
    onClose?.();
  }, [setStep, setIsGroup, setGroupParticipants, setName, setSubmitting, onClose]);

  const handleSubmit = useCallback(async (user?: User) => {
    try {
      setSubmitting(true);
      let payload;
      if (isGroup) {
        payload = {
          name: name.trim(),
          participant_ids: groupParticipants.map((p) => p.id),
          type: EConversationTypes.GROUP,
        };
      } else {
        payload = {
          participant_ids: [user!.id],
          type: EConversationTypes.PRIVATE,
        };
      }
      const { data: conversation } = await createConversation(orgId, payload);
      setSubmitting(false);
      handleReset();
      onNewConversation?.(parseNewConversation(conversation, loggedUser, loggedUser.id));
      history.push(`${basePath}/${conversation.id}`);
    } catch (error: any) {
      Alert.forStatus(error.status_code, {
        warning: t(isGroup ?
          'chat:new_conversation_form_warning_create_group' :
          'chat:new_conversation_form_warning_create_conversation'),
        error: t(isGroup ?
          'chat:new_conversation_form_error_create_group' :
          'chat:new_conversation_form_error_create_conversation'),
      });
    }
  }, [
    name, groupParticipants, isGroup, orgId, history, basePath, loggedUser, t,
    onNewConversation, handleReset, setSubmitting,
  ]);

  const onGoBack = useCallback(() => {
    if (step === 0) return setIsGroup(false);
    if (step === 1) return setStep(0);
  }, [step]);

  return (
    <Modal
      show={show}
      fullWidthContent
      className="NewConversationForm modal--color-grey"
      size="small"
      title={isGroup ? t('chat:new_conversation_form_title_group') : t('chat:new_conversation_form_title')}
      content={
        <>
          {step === 0 && (isGroup ? (
            <ParticipantsList
              delay
              isGroup
              participants={groupParticipants}
              loggedUser={loggedUser}
              onToggle={handleToggleGroupParticipant}
            />
          ) : (
            <ParticipantsList
              delay
              isGroup={false}
              loggedUser={loggedUser}
              onSelect={handleSubmit}
              onToggleGroup={() => setIsGroup(true)}
            />
          ))}
          {step === 1 && (
            <List
              items={groupParticipants}
              header={(
                <>
                  <Row>
                    <Group>
                      <TextInput
                        ref={ref}
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder={t('chat:conversation_form_name_input_placeholder')}
                      />
                    </Group>
                  </Row>
                  <SectionHeader
                    title={t('chat:new_conversation_form_participants_count', { count: groupParticipants.length })}
                  />
                </>
              )}
              renderRow={({ item }) => (
                <Participant item={item} loggedUser={loggedUser}>
                  {groupParticipants.length > 1 && (
                    <Icon
                      type="close"
                      className="tw-cursor-pointer"
                      onClick={() => handleRemoveGroupParticipant(item)}
                    />
                  )}
                </Participant>
              )}
            />
          )}
        </>
      }
      onBack={(isGroup && onGoBack) || undefined}
      onClose={handleReset}
      onShow={onShow}
      footerNote={isGroup && (
        <div>
          {t('chat:new_conversation_form_selected_participants_count', { count: groupParticipants.length })}
        </div>
      )}
      footer={isGroup && (
        <>
          {step === 0 && (
            <Button
              type="primary"
              onClick={() => setStep(1)}
              disabled={groupParticipants.length === 0}
            >
              {t('chat:new_conversation_form_next_step')}
            </Button>
          )}
          {step === 1 && (
            <Button
              isLoading={submitting}
              type="primary"
              onClick={() => handleSubmit()}
              disabled={groupParticipants.length === 0 || name.trim().length === 0}
            >
              {t('chat:new_conversation_form_create')}
            </Button>
          )}
        </>
      )}
    >
      {children}
    </Modal>
  );
};

export default NewConversationForm;
