import * as React from 'react';
import * as R from 'ramda';

// Components
import Image from '@common/components/image';
import ImagePlaceholder from '@common/components/image-placeholder';

// Types
import type { LoggedUser } from '@common/types/objects';
import type { FullConversation } from '@modules/chat/types/objects';

const containerClass = 'ImageItem__image__container';

export type Props = {
  className?: string,
  loggedUser: LoggedUser,
  conversation: FullConversation
};

const ConversationImageComponent = ({ loggedUser, conversation, className }: Props) => {
  const classNames = containerClass + (className ? ` ${className}` : '');

  // Show placeholder when logged user isn't a participant anymore
  if (conversation.has_left || conversation.participants.length === 0) {
    return (
      <div className={classNames}>
        <ImagePlaceholder size={40} name={conversation.name || ''} />
      </div>
    );
  }

  // Don't show the logged user's profile image
  const participants = R.sort((a) => (a.id === loggedUser.id ? 1 : -1), conversation.participants);

  return (
    <div className={classNames}>
      {conversation.conversation_type === 'group' ? (
        <div className="Image ImageItem__image">
          <Image size={40} src={conversation.group_img || '/static/images/group_chat_placeholder.png'} alt={conversation.name} />
        </div>
      ) : (
        <div className="Image ImageItem__image">
          <Image size={40} src={participants[0].profile_img} alt={participants[0].full_name} />
        </div>
      )}
    </div>
  );
};

export default ConversationImageComponent;
