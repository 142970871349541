import React from 'react';
import { Trans, useTranslation, TFunction } from 'react-i18next';
import { sortBy } from 'lodash';

// Components
import { ProfileImage } from '@common/components/profile-image';
import Icon from '@common/components/icon';
import GroupConversationForm from '@modules/chat/forms/edit-group-conversation';

// Selectors
import { useAppSelector } from '@common/hooks/redux';
import { selected } from '@modules/core/selectors/logged-user';

// Types
import { User, UserStatus } from '@common/types/objects';
import { EConversationActivityTypes } from '@modules/chat/definitions';
import { FullConversation, PrivateMessage } from '@modules/chat/types/objects';

const getIcon = (message: PrivateMessage) => {
  switch (message.activity_type) {
    case EConversationActivityTypes.USER_ADDED:
    case EConversationActivityTypes.ADMIN_ADDED:
      return 'group_add';
    case EConversationActivityTypes.USER_REMOVED:
    case EConversationActivityTypes.USER_LEFT:
      return 'close';
    case EConversationActivityTypes.CHANGED_NAME:
      return 'edit';
    default:
      return null;
  }
};

const nameWrapper = (userName?: string) => {
  if (userName) {
    return <>arbitrary content that will get ignored</>;
  }
  return <i />; // we want the 'chat:deleted_user' to be in italics
};

const nameLabel = (t: TFunction, userName?: string) => {
  if (userName) {
    return userName;
  }
  return t('chat:deleted_user');
};

const conversationCreated = (t: TFunction, actor?: User, actorId?: string, loggedUserId?: string) => {
  if (actorId === loggedUserId) {
    return { i18nKey: 'chat:activity_text_conversation_created_self' };
  }
  return {
    i18nKey: 'chat:activity_text_conversation_created',
    values: { actorFullName: nameLabel(t, actor?.full_name) },
    components: [nameWrapper(actor?.full_name)]
  };
};

const changedName = (t: TFunction, actor?: User, name?: string, actorId?: string, loggedUserId?: string) => {
  if (actorId === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_changed_name_self',
      values: { name }
    };
  }
  return {
    i18nKey: 'chat:activity_text_changed_name',
    values: {
      name,
      actorFirstName: nameLabel(t, actor?.first_name)
    },
    components: [nameWrapper(actor?.first_name)]
  };
};

const userLeft = (t: TFunction, userId?: string, loggedUserId?: string, user?: User) => {
  if (userId === loggedUserId) {
    return { i18nKey: 'chat:activity_text_user_left_self' };
  }
  return {
    i18nKey: 'chat:activity_text_user_left',
    values: { actorFirstName: nameLabel(t, user?.first_name) },
    components: [nameWrapper(user?.first_name)]
  };
};

const userAdded = (t: TFunction, userId?: string, loggedUserId?: string, actor?: User, actorId?: string, user?: User) => {
  if (userId === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_user_added_you',
      values: { actorFirstName: nameLabel(t, actor?.first_name) },
      components: [nameWrapper(actor?.first_name)]
    };
  }
  if (actorId === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_user_added_self',
      values: { userFirstName: nameLabel(t, user?.first_name) },
      components: [nameWrapper(user?.first_name)]
    };
  }
  return {
    i18nKey: 'chat:activity_text_user_added',
    values: {
      actorFirstName: nameLabel(t, actor?.first_name),
      userFirstName: nameLabel(t, user?.first_name)
    },
    components: [
      nameWrapper(actor?.first_name),
      nameWrapper(user?.first_name)
    ]
  };
};

const userRemoved = (t: TFunction, userId?: string, loggedUserId?: string, actor?: User, user?: User) => {
  if (userId === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_user_removed_you',
      values: { actorFirstName: nameLabel(t, actor?.first_name) },
      components: [nameWrapper(actor?.first_name)]
    };
  }
  if (actor?.id === loggedUserId) {
    return {
      i18nKey: 'chat:activity_text_user_removed_self',
      values: { userFirstName: nameLabel(t, user?.first_name) },
      components: [nameWrapper(user?.first_name)]
    };
  }
  return {
    i18nKey: 'chat:activity_text_user_removed',
    values: {
      actorFirstName: nameLabel(t, actor?.first_name),
      userFirstName: nameLabel(t, user?.first_name)
    },
    components: [
      nameWrapper(actor?.first_name),
      nameWrapper(user?.first_name)
    ]
  };
};

const adminAdded = (t: TFunction, userId?: string, loggedUserId?: string, user?: User) => {
  if (userId === loggedUserId) {
    return { i18nKey: 'chat:activity_text_admin_added_self' };
  }
  return {
    i18nKey: 'chat:activity_text_admin_added',
    values: { userFirstName: nameLabel(t, user?.first_name) },
    components: [nameWrapper(user?.first_name)]
  };
};

export const createTextForActivity = (t: TFunction, message: PrivateMessage, loggedUserId: string) => {
  const {
    actor_id: actorId, user_id: userId, actor, user, new_name: newName
  } = message.meta_data || {};

  switch (message.activity_type) {
    case EConversationActivityTypes.CONVERSATION_CREATED:
      return conversationCreated(t, actor, actorId, loggedUserId);
    case EConversationActivityTypes.CHANGED_NAME:
      return changedName(t, actor, newName, actorId, loggedUserId);
    case EConversationActivityTypes.USER_LEFT:
      return userLeft(t, userId, loggedUserId, user);
    case EConversationActivityTypes.USER_ADDED:
      return userAdded(t, userId, loggedUserId, actor, actorId, user);
    case EConversationActivityTypes.USER_REMOVED:
      return userRemoved(t, userId, loggedUserId, actor, user);
    case EConversationActivityTypes.ADMIN_ADDED:
      return adminAdded(t, userId, loggedUserId, user);
    default:
      return null;
  }
};

interface ActivityProps {
  item: PrivateMessage;
  conversation: FullConversation;
  usersStatus: Record<string, UserStatus | null>;
  onEditParticipants: (
    conversationId: string, activities: PrivateMessage | PrivateMessage[], participants: User | User[],
  ) => void;
}

const Activity = ({ item, conversation, usersStatus, onEditParticipants }: ActivityProps) => {
  const { t } = useTranslation();
  const loggedUser = useAppSelector(selected);

  if (item.activity_type === EConversationActivityTypes.CONVERSATION_CREATED) {
    const participants = sortBy(conversation.participants, (a) => (a.id === loggedUser.id ? 1 : -1));
    return (
      <div className="ConversationActivity ConversationActivity--margin-bottom">
        <div className="ConversationActivity__Content">
          <div
            className="ConversationActivity__Content__Participants"
            onClick={() => {
              // Warning! seems dangerous, what if the className changes?
              const el = document.querySelector('.Conversation__Header .ImageItem');
              if (el) (el as HTMLElement).click();
            }}
          >
            {participants.length > 3 && (
              <div className="ConversationActivity__Content__Participants__More ImagePlaceholder">
                {`+ ${participants.length - 2}`}
              </div>
            )}
            {participants.slice(0, participants.length === 3 ? 3 : 2).map((participant) => (
              <ProfileImage
                key={participant.id}
                size={60}
                user={participant}
                formattedStatus={usersStatus[participant.id]}
              />
            ))}
          </div>
          <h2>{conversation.name}</h2>
          <h3>
            <Trans
              {...createTextForActivity(t, item, loggedUser.id)}
            />
          </h3>
          {!conversation.has_left && conversation.is_admin && (
            <GroupConversationForm
              usersStatus={usersStatus}
              onlyParticipants
              conversation={conversation}
              onEditParticipants={onEditParticipants}
            >
              <a>{t('chat:activity_add_colleagues')}</a>
            </GroupConversationForm>
          )}
        </div>
      </div>
    );
  }

  const text = createTextForActivity(t, item, loggedUser.id);
  if (!text) return null;

  const icon = getIcon(item);
  return (
    <div className="ConversationActivity">
      {icon && <Icon type={icon} />}
      <small><Trans {...text} /></small>
    </div>
  );
};

export default Activity;
