import { groupBy, pick } from 'lodash';
import moment from 'moment';

import { User } from '@common/types/objects';
import { APIResponsePrivateMessage, PrivateMessage } from '../types/objects';
import { APIResponseFetchMessages } from '../api/fetch-messages';

const getIsPartOfSection = (message: PrivateMessage, lastUserSection?: { user?: User; messages: PrivateMessage[] }) => {
  return message.user?.id === lastUserSection?.user?.id && message.type === lastUserSection?.messages[0].type;
};

export const formatMessages = (messages: PrivateMessage[]) => {
  const days = groupBy(messages, (message) => moment(message.created_at).format('YYYY-MM-DD'));
  return Object.keys(days).map((date) => {
    const items = days[date];
    return {
      date: items[0].created_at,
      sections: items.reduce((acc, message: PrivateMessage, i: number) => {
        const isPartOfSection = getIsPartOfSection(message, acc[acc.length - 1]);
        if (i === 0 || !isPartOfSection) {
          acc.push({
            user: message.user || undefined,
            messages: [message],
          });
        } else {
          acc[acc.length - 1].messages.push(message);
        }
        return acc;
      }, [] as { user?: User; messages: PrivateMessage[] }[]),
    };
  });
};

// Todo: there should be different types for private message and conversation activity
export const parseMessage = (
  message: APIResponsePrivateMessage,
  related?: APIResponseFetchMessages['meta']['related'],
): PrivateMessage => {
  const user = related?.users.find((u) => u.id === message.user_id);
  return {
    ...pick(message, ['id', 'user_id', 'reactions', 'seen']),
    ...pick(message.source, ['object_id', 'text', 'activity_type', 'created_at']),
    type: message.object_type,
    // Conversation activities don't have a user
    user: user || null,
    parent_message: message.parent_message ? parseMessage(message.parent_message, related) : undefined,
    attachments: message.children.map((child) => child.source),
    deleted_at: message.deleted_at || undefined,
    meta_data: message.source.meta_data ? {
      ...message.source.meta_data,
      actor: related?.users.find((u) => u.id === message.source.meta_data?.actor_id),
      user: related?.users.find((u) => u.id === message.source.meta_data?.user_id),
    } : undefined,
  };
};

export const parseMessages = (
  messages: APIResponsePrivateMessage[],
  users: APIResponseFetchMessages['meta']['related'],
): PrivateMessage[] => {
  // reverse data to show the latest messages first, should this be done on the API?
  return messages.reverse().map((message) => parseMessage(message, users));
};

