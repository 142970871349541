import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Linkify from 'react-linkify';
import GraphemeSplitter from 'grapheme-splitter';
import moment from 'moment';

// Components
import Icon from '@common/components/icon';
import { EmojiReactions } from '@common/components/emoji-reactions';
import MessageActions from '../message-actions';
import MessageAttachment from '../message-attachment';

// Utils
import { combineClassNames } from '@common/utils/combineClassNames';

// Types
import { PrivateMessage } from '@modules/chat/types/objects';
import { AllowedEmoji, LoggedUser } from '@common/types/objects';

const TIME_TO_DELETE_MESSAGE = 60 * 60 * 2;

interface MessageProps {
  item: PrivateMessage;
  isGroup: boolean;
  hasLeft: boolean;
  index: number;
  conversationId: string;
  loggedUser: LoggedUser;
  allowedEmojis: AllowedEmoji[];
  onReply: (messageId: string) => void;
  onRemove: (messageId: string) => void;
  onSetEmojiReaction: (messageId: string, emoji: AllowedEmoji, isSelected: boolean) => void;
}

const splitter = new GraphemeSplitter();
let regex: RegExp | undefined;
try {
  // eslint-disable-next-line prefer-regex-literals
  regex = new RegExp('\\p{Extended_Pictographic}', 'u');
} catch (err) {
  // Regex not supported
}

const getSize = (size: number) => {
  switch (size) {
    case 1: return 36;
    case 2: return 32;
    case 3: return 24;
    default: return 14;
  }
};

const getFontSize = (text: string) => {
  const splitted = splitter.splitGraphemes(text); // Some icons contain of multiple unicode characters so we have to use a lib for this
  const shouldResize = regex && splitted && splitted.length <= 3 && splitted.every((c) => regex?.test(c)); // If length is 3 or below and they are all emojis
  return shouldResize ? getSize(splitted.length) : null;
};

const LinkifyDecorator = (decoratedHref: string, decoratedText: string, key: number) => (
  <a target="blank" rel="noopener" className="linkified" href={decoratedHref} key={key}>
    {decoratedText}
  </a>
);

const MessageComponent = ({
  item, isGroup, index, conversationId, loggedUser, allowedEmojis, hasLeft,
  onReply, onRemove, onSetEmojiReaction,
}: MessageProps) => {
  const { t } = useTranslation();

  const [showSettings, setShowSettings] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [canDelete, setCanDelete] = useState(false);

  const timestamp = moment(item.created_at).format('HH:mm');
  const showName = isGroup && index === 0 && item.user?.id !== loggedUser.id;
  const className = combineClassNames('PrivateMessage', {
    'PrivateMessage--state-sent': item.user?.id === loggedUser.id.toString(),
    'PrivateMessage--state-received': item.user?.id !== loggedUser.id.toString(),
  });

  const {
    textFontSize, textLineHeight, replyFontSize, replyLineHeight,
  } = useMemo(() => {
    const textSize = getFontSize(item.text) || undefined;
    const replySize = (item.parent_message?.text && getFontSize(item.parent_message?.text)) || undefined;
    return {
      textFontSize: textSize && `${textSize}px`,
      textLineHeight: textSize && `${textSize * 1.2}px`,
      replyFontSize: replySize && `${replySize}px`,
      replyLineHeight: replySize && `${replySize * 1.2}px`,
    };
  }, [item.text, item.parent_message]);

  const handleMouseEnter = useCallback(() => {
    setShowSettings(true);
    // Limit to 2 hours after posting
    if ((+new Date() - +new Date(item.created_at)) / 1000 < TIME_TO_DELETE_MESSAGE) setCanDelete(true);
  }, [setShowSettings]);

  const handleSetEmojiReaction = useCallback((emoji: AllowedEmoji, isSelected: boolean) => {
    if (hasLeft) return;
    onSetEmojiReaction(item.id, emoji, isSelected);
  }, [onSetEmojiReaction, item.id, hasLeft]);

  if (item.deleted_at) {
    return (
      <div className={className}>
        <div className="PrivateMessage__Container">
          <div className="PrivateMessage__Inner">
            {showName && <b>{item.user?.full_name}</b>}
            <span className="Linkify">
              <span className="PrivateMessage__Deleted">
                <Icon type="not_interested" />
                {t('chat:message_removed')}
              </span>
              <small className="PrivateMessage__timestamp PrivateMessage__timestamp--hidden">{timestamp}</small>
            </span>
            <small className="PrivateMessage__timestamp">{timestamp}</small>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={className}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setShowSettings(false)}
    >
      {(item.text) && (
        <div className="PrivateMessage__Container">
          <div className="PrivateMessage__Inner">
            {!hasLeft && (showSettings || isDropdownOpen || isModalOpen) && (
              <MessageActions
                message={item}
                allowedEmojis={allowedEmojis}
                conversationId={conversationId}
                canDelete={canDelete && item.user?.id === loggedUser.id}
                onReply={onReply}
                onRemove={onRemove}
                setDropdownOpen={setDropdownOpen}
                setModalOpen={setModalOpen}
                setShowSettings={setShowSettings}
                onSetEmojiReaction={onSetEmojiReaction}
              />
            )}
            {showName && <b>{item.user?.full_name}</b>}
            {!!item.parent_message && (
              <div className="PrivateMessage__Reply">
                <div className="PrivateMessage__Reply__Border" />
                <div className="PrivateMessage__Reply__Inner">
                  <b>
                    {item.parent_message.user?.id !== loggedUser.id
                      ? item.parent_message.user?.full_name
                      : t('chat:message_user_you')}
                  </b>
                  {(item.parent_message.deleted_at && (
                    <div className="PrivateMessage__Deleted">
                      {t('chat:message_removed')}
                    </div>
                  )) || (item.parent_message.text && (
                    <span className="Linkify">
                      <Linkify componentDecorator={LinkifyDecorator}>
                        <span style={{ fontSize: replyFontSize, lineHeight: replyLineHeight }}>
                          {item.parent_message.text}
                        </span>
                      </Linkify>
                    </span>
                  ))}
                </div>
              </div>
            )}
            {item.text && (
              <span className="Linkify">
                <Linkify componentDecorator={LinkifyDecorator}>
                  <span style={{ fontSize: textFontSize, lineHeight: textLineHeight }}>
                    {item.text}
                  </span>
                  <small className="PrivateMessage__timestamp PrivateMessage__timestamp--hidden">
                    {timestamp}
                  </small>
                </Linkify>
              </span>
            )}
            <small className="PrivateMessage__timestamp">
              {timestamp}
            </small>
          </div>

          <EmojiReactions
            className="PrivateMessage__EmojiReactions"
            reactions={item.reactions}
            onSetReaction={handleSetEmojiReaction}
          />
        </div>
      )}

      {item.attachments.map((attachment) => (
        <MessageAttachment
          attachment={attachment}
          timestamp={timestamp}
          conversationId={conversationId}
          showActions={showSettings || isDropdownOpen || isModalOpen}
          canDelete={canDelete && item.user?.id === loggedUser.id}
          onRemove={() => onRemove(item.id)}
          setDropdownOpen={setDropdownOpen}
          setModalOpen={setModalOpen}
          setShowSettings={setShowSettings}
        />
      ))}
    </div>
  );
};

export default MessageComponent;
