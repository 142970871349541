import Api from '@common/services/api';
import { User } from '@common/types/objects';
import { APIResponseConversation } from '../types/objects';

type UpdateParticipantsResponse = {
  data: APIResponseConversation;
};

export const updateParticipants = async (orgId: string, conversationId: string, users: User | User[]) => {
  return Api.put<UpdateParticipantsResponse>(`/v3/organisations/${orgId}/conversations/${conversationId}/participants`, {
    [Array.isArray(users) ? 'add' : 'remove']: Array.isArray(users) ? users.map((user) => user.id) : [users.id],
  });
};

