import { omit } from 'lodash';

import { Api } from '@common/services/api';

import type { PostMessageFormData } from '../forms/post-message';
import type { APIResponsePrivateMessage } from '../types/objects';

export type APIResponsePostMessage = {
  data: APIResponsePrivateMessage;
};

export const postMessage = async (organisationId: string, conversationId: string, values: PostMessageFormData) => {
  const attachments = [];
  if (values.gif) {
    attachments.push(values.gif);
  } else {
    values.attachments.forEach((attachment) => attachments.push(attachment));
  }
  const requests = [];
  if (values.text) {
    const message = { text: values.text, message_id: values.message_id };
    // if message_id is provided, we are replying to a message
    // would be better if API accepts message_id to be null
    requests.push(message.message_id ? message : omit(message, 'message_id'));
  }
  if (attachments.length > 0) {
    requests.push({ text: '', files: attachments.map((attachment) => attachment.id) });
  }
  return Promise.all(requests.map(async (request) => {
    const response = await Api.post<APIResponsePostMessage>(
      `/v3/organisations/${organisationId}/conversations/${conversationId}/messages`, request,
    );
    return response.data;
  }));
};
