import { pick } from 'lodash';

import { Api } from '@services/api';

import type { APIResponseNewConversation } from './create-conversation';
import type { ConversationFormState } from '../forms/conversation/conversation';
import { FullConversation } from '../types/objects';

interface UpdateConversationResponse {
  data: APIResponseNewConversation;
}

export const updateConversation = async (orgId: string, conversation: FullConversation, data: ConversationFormState) => {
  const promises = [];

  if (data.group_img) {
    const payload = new FormData();
    payload.append('image', data.group_img.file);
    payload.append('extension', 'jpg');
    promises.push(Api.post<UpdateConversationResponse>(
      `/v3/organisations/${orgId}/conversations/${conversation.id}/group-image`, payload,
    ));
  }

  if (data.name || data.description) {
    promises.push(Api.put<UpdateConversationResponse>(
      `/v3/organisations/${orgId}/conversations/${conversation.id}`, pick(data, ['name', 'description']),
    ));
  }

  return Promise.all(promises);
};
