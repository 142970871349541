export const EMessageTypes = <const>{
  MESSAGE: 'private_message',
  ACTIVITY: 'conversation_activity',
};

export const EConversationActivityTypes = <const>{
  USER_REMOVED: 'user_removed',
  USER_ADDED: 'user_added',
  USER_LEFT: 'user_left',
  CHANGED_NAME: 'changed_name',
  CONVERSATION_CREATED: 'conversation_created',
  ADMIN_ADDED: 'admin_added',
};

export const EConversationTypes = <const>{
  PRIVATE: 'private',
  GROUP: 'group',
};

export enum EConversationFilters {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export interface RouteParams {
  filter?: EConversationFilters;
  conversationId?: string;
  userId?: string;
}
