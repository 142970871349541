import { useEffect, useState } from 'react';

// Services
import SocketService from '@common/services/socket';

// Utils
import { formatUserStatus } from '@modules/core/selectors/logged-user';

// Types
import { UserStatus } from '@common/types/objects';
import { EConversationTypes } from '../definitions';
import { FullConversation } from '@modules/chat/types/objects';
import { ESocketEvents } from '@modules/core/definitions';

type ChangedUserStatusPayload = {
  organisation_id: string;
  status: UserStatus
};

const getUserStatus = (status?: UserStatus | null) => {
  if (!status) return null;
  return formatUserStatus(status);
};

export const usePusherUserStateListener = (conversations: FullConversation[]) => {
  const [usersState, setUsersState] = useState<Record<string, UserStatus | null>>({});

  // Add new participants when a conversation is added or updated
  useEffect(() => {
    setUsersState((prev) => ({
      ...conversations.reduce((acc, c) => {
        if (c.conversation_type === EConversationTypes.PRIVATE && c.participant) {
          if (!acc[c.participant.id]) {
          acc[c.participant.id] = getUserStatus(c.participant.membership?.status);
        }
      }
      if (c.conversation_type === EConversationTypes.GROUP && c.participants) {
        c.participants.forEach((p) => {
          if (!acc[p.id]) {
            acc[p.id] = getUserStatus(p.membership?.status);
          }
        });
      }
        return acc;
      }, prev),
    }));
  }, [conversations]);

  // Update event listeners when users change
  useEffect(() => {
    if (Object.keys(usersState).length === 0) return;
    Object.keys(usersState).forEach((userId) => {
      SocketService.registerEvent(`user-${userId}`, ESocketEvents.USER_STATUS_UPDATED, (data: ChangedUserStatusPayload) => {
        setUsersState((prev) => ({
          ...prev,
          [userId]: getUserStatus(data.status),
        }));
      }, 'chat');
    });
  }, [usersState]);

  return usersState;
};
