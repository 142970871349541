import Api from '@common/services/api';
import { APIResponseConversation } from '../types/objects';
import { APIResponseFetchMessages } from './fetch-messages';

type APIResponseFetchConversation = {
  data: APIResponseConversation;
  meta: APIResponseFetchMessages['meta'];
};

const fetchConversation = async (orgId: string, conversationId: string) => {
  return Api.get<APIResponseFetchConversation>(`/v3/organisations/${orgId}/conversations/${conversationId}`);
};

export default fetchConversation;
