import React, { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@common/components/button';
import Spinner from '@common/components/spinner';
import Placeholder from '@common/components/placeholder';

// Chat components
import ConversationActions from '@modules/chat/components/conversation-actions';
import ConversationArchivedActions from '@modules/chat/components/conversation-archived-actions';
import ConversationHeader from '@modules/chat/components/conversation-header/conversation-header';
import EditGroupConversationForm from '@modules/chat/forms/edit-group-conversation';
import Messages from '../messages';

// Types
import { FullConversation, PrivateMessage } from '@modules/chat/types/objects';
import { EConversationTypes } from '@modules/chat/definitions';
import type { User, UserStatus } from '@common/types/objects';
import type { ConversationFormPayload } from '@modules/chat/forms/conversation/conversation';

import { TrackComponent, EEventNames } from '../../../../../client/analytics';

// Map conversation type to meta data for Mixpanel
const conversationTypeToMeta = {
  private: 'Private',
  group: 'Group',
};

interface ConversationProps {
  conversation: FullConversation | null;
  empty: boolean;
  messages: PrivateMessage[] | null;
  usersStatus: Record<string, UserStatus | null>;
  setMessages: Dispatch<SetStateAction<PrivateMessage[] | null>>;
  onNewMessage: (id: string, message: PrivateMessage) => void;
  onOpenProfile: (id: string) => void;
  onToggleNotifications: (id: string, mute: boolean) => void;
  onToggleArchive: (id: string, shouldArchive: boolean) => void;
  onRemoveConversation: (id: string) => void;
  onLeave: (id: string) => void;
  onEditGroupAdmin: (id: string, user: User, add: boolean) => void;
  onUpdateConversation: (id: string, data: ConversationFormPayload) => void;
  setNewConversationModalVisible: (visible: boolean) => void;
  onEditParticipants: (
    conversationId: string, activities: PrivateMessage | PrivateMessage[], participants: User | User[],
  ) => void;
  onUpdateLastMessage: (
    id: string, propName: keyof PrivateMessage, value: PrivateMessage[keyof PrivateMessage], messageId: string,
  ) => void;
}

const Conversation = ({
  conversation, empty, messages, usersStatus,
  setMessages, onNewMessage, onOpenProfile, onToggleNotifications, onToggleArchive,
  onRemoveConversation, onEditGroupAdmin, onEditParticipants, onLeave, onUpdateConversation,
  setNewConversationModalVisible, onUpdateLastMessage,
}: ConversationProps) => {
  const { t } = useTranslation();
  const userStatus = conversation?.participant?.id ? usersStatus[conversation.participant.id] : null;
  const isGroup = conversation?.conversation_type === EConversationTypes.GROUP;

  return (
    <>
      {!conversation && !empty && <Spinner centered />}
      {conversation ? (
        <TrackComponent
          key={conversation.id}
          eventName={EEventNames.VIEWED_CHAT_CONVERSATION}
          properties={{
            type: conversationTypeToMeta[conversation.conversation_type]
          }}
        >
          <div className="Conversation">
            <div className="Conversation__Header fs-mask">
              {isGroup && !conversation.has_left ? (
                <EditGroupConversationForm
                  conversation={conversation}
                  usersStatus={usersStatus}
                  onOpenProfile={onOpenProfile}
                  onEditGroupAdmin={onEditGroupAdmin}
                  onEditParticipants={onEditParticipants}
                  onUpdateConversation={onUpdateConversation}
                  onLeave={onLeave}
                >
                  <ConversationHeader conversation={conversation} />
                </EditGroupConversationForm>
              ) : (
                <ConversationHeader
                  isGroup={isGroup}
                  conversation={conversation}
                  participantStatus={userStatus || undefined}
                />
              )}
              {conversation.is_archived ? (
                <ConversationArchivedActions
                  conversation={conversation}
                  onUnarchive={() => onToggleArchive(conversation.id, false)}
                  onRemoveConversation={onRemoveConversation}
                />
              ) : (
                <ConversationActions
                  usersStatus={usersStatus}
                  conversation={conversation}
                  onOpenProfile={onOpenProfile}
                  onToggleNotifications={onToggleNotifications}
                  onRemoveConversation={onRemoveConversation}
                  onArchive={(id: string) => onToggleArchive(id, !conversation.is_archived)}
                  onEditGroupAdmin={onEditGroupAdmin}
                  onEditParticipants={onEditParticipants}
                  onUpdateConversation={onUpdateConversation}
                  onLeave={onLeave}
                />
              )}
            </div>
            <Messages
              conversation={conversation}
              messages={messages}
              usersStatus={usersStatus}
              onEditParticipants={onEditParticipants}
              onNewMessage={onNewMessage}
              setMessages={setMessages}
              onUpdateLastMessage={onUpdateLastMessage}
            />
          </div>
        </TrackComponent>
      ) : empty && (
        <Placeholder
          image="/static/images/placeholders/chat.svg"
          title={t('chat:conversations_placeholder_title')}
          description={t('chat:conversations_placeholder_description')}
          action={(
            <Button
              type="primary"
              className="pull-right"
              iconRight="add"
              onClick={() => setNewConversationModalVisible(true)}
            >
              {t('chat:conversations_placeholder_create_new_chat')}
            </Button>
          )}
        />
      )}
    </>
  );
};

export default Conversation;
