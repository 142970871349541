import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';

// Components
import { Button } from '@common/components/button';
import ButtonGroup from '@common/components/button-group';
import Container from '@common/components/container';
import { PureList } from '@common/components/list/pure';
import Spinner from '@common/components/spinner';
import Placeholder from '@common/components/placeholder/placeholder';
import SearchBar from '@common/components/search-bar';

// Chat components
import NewConversationForm from '../../forms/new-conversation';
import ConversationItem from '../../components/conversation-item';
import Conversation from '../conversation';

// API
import { fetchConversations } from '@modules/chat/api/fetch-conversations';
import { toggleNotifications } from '@modules/chat/api/toggle-notifications';
import { toggleArchive } from '@modules/chat/api/toggle-archive';
import { removeConversation } from '@modules/chat/api/remove-conversation';
import { updateAdmins } from '@modules/chat/api/update-admins';
import { leaveConversation } from '@modules/chat/api/leave-conversation';
import { createConversation } from '@modules/chat/api/create-conversation';
import fetchConversation from '@modules/chat/api/fetch-conversation';
import { fetchMessages } from '@modules/chat/api/fetch-messages';

// Selectors
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';
import * as networkSelector from '../../../network/selectors/network';
import { selected } from '@modules/core/selectors/logged-user';

// Hooks
import { useAppSelector } from '@common/hooks/redux';
import { usePusherConversationListener } from '@modules/chat/hooks/use-pusher-conversation-listener';
import { useUpdateNotifications } from '@common/hooks/use-update-notifications';
import { usePusherUserStateListener } from '@modules/chat/hooks/use-pusher-user-state-listener';

// Utils
import { combineClassNames } from '@common/utils/combineClassNames';
import { parseAndSortConversations, parseConversation, sortConversations } from '@modules/chat/utils/conversations';
import { createActivity } from '@modules/chat/utils/activity';

// Types
import type { Pagination } from '@common/components/list';
import type { FullConversation, PrivateMessage } from '@modules/chat/types/objects';
import type { User } from '@common/types/objects';
import type { ConversationFormPayload } from '@modules/chat/forms/conversation/conversation';
import { EConversationActivityTypes, EConversationFilters, EConversationTypes, RouteParams } from '@modules/chat/definitions';

// Styles
import '../../styles.scss';

interface ChatProps {
  basePath: string;
}

const getPath = (basePath: string, filter?: EConversationFilters, conversationId?: string, search?: string) => {
  return filter ?
    `${basePath}/filter/${filter}${conversationId ? `/${conversationId}` : ''}${search ? `?search=${search}` : ''}` :
    `${basePath}${conversationId ? `/${conversationId}` : ''}${search ? `?search=${search}` : ''}`;
};

const Chat = ({ basePath }: ChatProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { filter, conversationId, userId } = useParams<RouteParams>();

  const orgId = useAppSelector(getCurrentOrgId);
  const network = useAppSelector(networkSelector.selected);
  const loggedUser = useAppSelector(selected);
  const handlePusherNewMessage = useUpdateNotifications();

  const [conversations, setConversations] = useState<FullConversation[] | null>(null);
  const [messages, setMessages] = useState<PrivateMessage[] | null>(null);
  const [pagination, setPagination] = useState<Pagination | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [selectConversation, setSelectConversation] = useState(!userId && !conversationId);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [currentConversation, setCurrentConversation] = useState<FullConversation | null>(null);
  const [isNewConversationModalVisible, setNewConversationModalVisible] = useState(false);
  const [search, setSearch] = useState<string | undefined>(undefined);

  // When the conversation is not in the list, fetch it and add it to the list
  const addNewConversation = useCallback(async (id: string) => {
    const { data, meta } = await fetchConversation(orgId, id);
    setConversations((prev) => [parseConversation(data, meta.related, loggedUser.id), ...(prev || [])]);
  }, [orgId, loggedUser.id, setConversations]);

  // Open the conversation when the conversationId is changed
  useEffect(() => {
    if (conversationId && currentConversation?.id !== conversationId && conversations !== null) {
      const conversation = conversations.find((c) => c.id === conversationId);
      if (conversation) {
        setCurrentConversation(conversation);
      } else {
        addNewConversation(conversationId);
      }
    }
  }, [conversationId, conversations, currentConversation?.id, setCurrentConversation, addNewConversation]);

  // Start private conversation with user and redirect to it
  useEffect(() => {
    const getConversationForUser = async () => {
      const { data } = await createConversation(orgId, {
        type: EConversationTypes.PRIVATE,
        participant_ids: [userId!],
      });
      history.replace(getPath(basePath, undefined, data.id));
    };
    if (userId) getConversationForUser();
  }, [userId, basePath, history]);

  const openNextConversation = useCallback((id: string) => {
    const nextConversations = conversations?.filter((c) => c.id !== id);
    if (nextConversations && nextConversations.length > 0) {
      history.push(getPath(basePath, filter, nextConversations[0].id, search));
    } else {
      history.push(getPath(basePath, filter, undefined, search));
      setCurrentConversation(null);
    }
  }, [conversations, basePath, filter, search, history, setCurrentConversation]);

  useEffect(() => {
    if (selectConversation && conversations && conversations.length > 0) {
      history.push(getPath(basePath, filter, conversations[0].id, search));
      setSelectConversation(false);
    }
  }, [conversations, userId]);

  // Fetching conversations and pagination
  useEffect(() => {
    const load = async () => {
      setIsLoading(true);
      const response = await fetchConversations({
        organisationId: orgId,
        filter,
        search,
      });
      const data = parseAndSortConversations(response.data, response.meta.related, loggedUser.id);
      setConversations(data);
      setPagination(response.meta.pagination);
      setIsLoading(false);
    };
    load();
  }, [
    orgId, filter, loggedUser.id, search, setConversations, setPagination, setIsLoading,
  ]);

  const handleLoadMore = useCallback(async () => {
    setIsLoadingMore(true);
    const response = await fetchConversations({
      organisationId: orgId,
      filter,
      nextCursor: pagination?.next_cursor,
      search,
    });
    // Todo: filter conversations that already exist, needed?
    setConversations((prev) => [
      ...(prev || []),
      ...parseAndSortConversations(response.data, response.meta.related, loggedUser.id),
    ]);
    setPagination(response.meta.pagination);
    setIsLoadingMore(false);
  }, [orgId, filter, pagination?.next_cursor, setConversations, setPagination, loggedUser.id, search]);

  // Update notifications count
  useEffect(() => {
    const updateNotifications = async () => handlePusherNewMessage(orgId);
    setTimeout(updateNotifications, 100);
  }, [orgId, conversations, handlePusherNewMessage]);

  // Opening profile
  const handleOpenProfile = useCallback((id: string) => {
    const path = basePath.includes('/admin')
      ? `/admin/users/${id}`
      : `/networks/${network.id}/users/${id}`;
    history.push(path);
  }, [basePath, history, network?.id]);

  // Adding new conversation (group or private)
  const handleNewConversation = useCallback((conversation: FullConversation) => {
    const alreadyExists = conversations?.find((c) => c.id === conversation.id);
    if (!alreadyExists) setConversations((prev) => [conversation, ...(prev || [])]);
    // Todo: if already exists, scroll into view
  }, [conversations]);

  const handleRemoveConversation = useCallback(async (id: string) => {
    await removeConversation(orgId, id);
    setConversations((prev) => prev?.filter((conversation) => conversation.id !== id) || null);
    if (currentConversation?.id === id) openNextConversation(id);
  }, [orgId, currentConversation?.id, setConversations, openNextConversation]);

  // Conversation mutations
  const updateConversation = useCallback((
    id: string,
    propName: keyof FullConversation,
    value: FullConversation[keyof FullConversation] | ((prev: any) => FullConversation[keyof FullConversation]),
  ) => {
    const updateAt = new Date().toISOString();
    setConversations((prev) => (prev && sortConversations(prev.map((conversation) => {
      if (conversation.id === id) {
        return {
          ...conversation,
          [propName]: value instanceof Function ? value(conversation[propName]) : value,
          updated_at: updateAt,
        };
      }
      return conversation;
    }))) || null);
    if (id === currentConversation?.id) {
      setCurrentConversation((prev) => (prev && {
        ...prev,
        [propName]: value instanceof Function ? value(prev[propName]) : value,
        updated_at: updateAt,
      }));
    }
  }, [setConversations, currentConversation]);

  const handleUpdateLastMessage = useCallback(async (
    id: string, propName: keyof PrivateMessage, value: PrivateMessage[keyof PrivateMessage], messageId: string,
  ) => {
    const conversation = conversations?.find((c) => c.id === id);
    if (conversation?.last_message?.id === messageId) {
      updateConversation(id, 'last_message', (prev) => ({ ...prev, [propName]: value }));
    }
  }, [conversations, updateConversation]);

  const handleNewMessage = useCallback(async (id: string, message: PrivateMessage) => {
    if (conversations?.find((c) => c.id === id)) {
      updateConversation(id, 'last_message', message);
      // Only update the messages if the current conversation is the one that changed
      if (currentConversation?.id === id) {
        setMessages((prev) => [...(prev || []), message]);
        if (!message.seen) {
          // To mark the conversation as seen, we need to fetch the messages
          await fetchMessages(orgId, id, true);
          updateConversation(id, 'last_message', (prev) => ({ ...prev, seen: true }));
        }
      }
    } else {
      addNewConversation(id);
    }
  }, [
    conversations, currentConversation?.id,
    updateConversation, setMessages, addNewConversation,
  ]);

  const handleUpdateConversation = useCallback(async (id: string, data: ConversationFormPayload) => {
    if (data.description) updateConversation(id, 'description', data.description);
    if (data.name) {
      updateConversation(id, 'name', data.name);
      handleNewMessage(id, createActivity(EConversationActivityTypes.CHANGED_NAME, {
        actor_id: loggedUser.id,
        new_name: data.name,
      }));
    }
    if (data.group_img) updateConversation(id, 'group_img', data.group_img);
  }, [updateConversation, handleNewMessage]);

  const handleToggleNotifications = useCallback(async (id: string, mute: boolean) => {
    await toggleNotifications(orgId, id, mute);
    updateConversation(id, 'is_muted', mute);
  }, [orgId, updateConversation]);

  const handleToggleArchive = useCallback(async (id: string, shouldArchive: boolean) => {
    await toggleArchive(orgId, id, shouldArchive);
    updateConversation(id, 'is_archived', shouldArchive);
    setConversations((prev) => prev?.filter((conversation) => conversation.id !== id) || null);
    if (currentConversation?.id === id) openNextConversation(id);
  }, [orgId, currentConversation?.id, updateConversation, setConversations, openNextConversation]);

  const handleEditGroupAdmin = useCallback(async (id: string, user: User, add: boolean) => {
    await updateAdmins(orgId, id, [user.id], add);
    if (add) {
      updateConversation(id, 'admin_ids', (prev: string[]) => [...(prev || []), user.id]);
      updateConversation(id, 'admins', (prev: User[]) => [...(prev || []), user]);
    } else {
      updateConversation(id, 'admin_ids', (prev: string[]) => prev?.filter((admin) => admin !== user.id) || null);
      updateConversation(id, 'admins', (prev: User[]) => prev?.filter((admin) => admin.id !== user.id) || null);
    }
  }, [orgId, updateConversation]);

  const handleEditParticipants = useCallback((
    id: string, activities: PrivateMessage | PrivateMessage[], participants: User | User[],
  ) => {
    if (Array.isArray(activities)) {
      setMessages((prev) => [...(prev || []), ...activities.slice(0, -1)]);
      // Only last activity should be set as last_message on conversation
      handleNewMessage(id, activities[activities.length - 1]);
    } else {
      handleNewMessage(id, activities);
    }

    if (Array.isArray(participants)) {
      updateConversation(id, 'participant_ids', (prev: string[]) => [...(prev || []), ...participants.map((p) => p.id)]);
      updateConversation(id, 'participants', (prev: User[]) => [...(prev || []), ...participants]);
    } else {
      updateConversation(id, 'participant_ids', (prev: string[]) => prev?.filter((p) => p !== participants.id) || null);
      updateConversation(id, 'participants', (prev: User[]) => prev?.filter((p) => p.id !== participants.id) || null);
      updateConversation(id, 'admins', (prev: User[]) => prev?.filter((p) => p.id !== participants.id) || null);
      updateConversation(id, 'admin_ids', (prev: string[]) => prev?.filter((p) => p !== participants.id) || null);
    }
  }, [updateConversation, handleNewMessage, setMessages]);

  const handleLeave = useCallback(async (id: string) => {
    await leaveConversation(orgId, id);
    updateConversation(id, 'has_left', true);
    handleNewMessage(id, createActivity(EConversationActivityTypes.USER_LEFT, {
      user_id: loggedUser.id,
    }));
  }, [orgId, updateConversation, handleNewMessage, loggedUser.id]);

  // Socket listener conversations
  usePusherConversationListener({
    orgId,
    loggedUserId: loggedUser.id,
    handleNewMessage,
    handleNewConversation,
  });

  // Socket listener users state
  const usersStatus = usePusherUserStateListener(conversations || []);

  return (
    <Container name="Chat">
      <Container.Content horizontal>
        <Container.SideBar size="large">
          <div className="MyConversations">
            <div className="wrapper__sidebar__search Form">
              <SearchBar
                defaultValue={search || undefined}
                placeholder={t('common:search_bar_placeholder')}
                onSearch={(newSearchTerm) => setSearch(newSearchTerm || undefined)}
              />
              <NewConversationForm
                basePath={basePath}
                show={isNewConversationModalVisible}
                onClose={() => setNewConversationModalVisible(false)}
                onShow={() => setNewConversationModalVisible(true)}
                onNewConversation={handleNewConversation}
              >
                <Button type="primary" className="pull-right" iconRight="add">
                  {t('chat:conversations_create_new_chat')}
                </Button>
              </NewConversationForm>
            </div>
            <div className="balloon MyConversations__container">
              <div className="balloon__header">
                <ButtonGroup
                  size="large"
                  active={filter || ''}
                  onChange={(newFilter) => {
                    setSelectConversation(true);
                    history.replace(newFilter ? `${basePath}/filter/${newFilter}` : basePath);
                  }}
                >
                  <Button value="">{t('chat:conversations_my_messages')}</Button>
                  <Button value="archived">{t('chat:conversations_archive_messages')}</Button>
                </ButtonGroup>
              </div>
              {filter === EConversationFilters.ARCHIVED && conversations?.length === 0 && (
                <Placeholder title={t('chat:archive_placeholder_title')} />
              )}
              {isLoading ? <Spinner centered /> : (
                <PureList
                  containerClassName={combineClassNames('MyConversations__list', {
                    'MyConversations__list--has-more': !!pagination?.next_cursor,
                  })}
                  items={conversations || []}
                  renderRow={ConversationItem}
                  rowProps={{
                    basePath,
                    currentConversationId: currentConversation?.id,
                    usersStatus,
                    onOpenProfile: handleOpenProfile,
                    onToggleNotifications: handleToggleNotifications,
                    onToggleArchive: handleToggleArchive,
                    onRemoveConversation: handleRemoveConversation,
                    onEditGroupAdmin: handleEditGroupAdmin,
                    onEditParticipants: handleEditParticipants,
                    onLeave: handleLeave,
                    onUpdateConversation: handleUpdateConversation,
                  }}
                  footer={pagination?.next_cursor && !isLoading && (
                    <div
                      className="MyConversations__List__ShowMore"
                      onClick={handleLoadMore}
                    >
                      {isLoadingMore ? <Spinner centered /> : t('chat:conversations_load_more')}
                    </div>
                  )}
                />
              )}
            </div>
          </div>
        </Container.SideBar>
        <Container.Column>
          <Conversation
            conversation={currentConversation}
            empty={conversations?.length === 0}
            messages={messages}
            usersStatus={usersStatus}
            setMessages={setMessages}
            onNewMessage={handleNewMessage}
            onOpenProfile={handleOpenProfile}
            onToggleNotifications={handleToggleNotifications}
            onToggleArchive={handleToggleArchive}
            onRemoveConversation={handleRemoveConversation}
            onEditGroupAdmin={handleEditGroupAdmin}
            onEditParticipants={handleEditParticipants}
            onLeave={handleLeave}
            onUpdateConversation={handleUpdateConversation}
            onUpdateLastMessage={handleUpdateLastMessage}
            setNewConversationModalVisible={setNewConversationModalVisible}
          />
        </Container.Column>
      </Container.Content>
    </Container>
  );
};

export default Chat;
