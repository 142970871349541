import { Api } from '@services/api';

import type { AllowedEmoji } from '@common/types/objects';

type GetAllowedEmojisApiResponse = {
  data: AllowedEmoji[];
};

export const getAllowedEmojis = async () => {
  return Api.get<GetAllowedEmojisApiResponse>('/v1/reactions');
};
