import React from 'react';
import { isNil, omitBy } from 'lodash';
import { reduxForm, Field, InjectedFormProps, WrappedFieldProps } from 'redux-form';
import { useTranslation } from 'react-i18next';

// Components
import * as Alert from '@common/services/alert';
import { Row, Group, TextInput } from '@common/components/form';
import { Button } from '@common/components/button';
import Spinner from '@common/components/spinner';
import FilePreview from '@common/components/file-preview/image';
import FileCropInput from '@common/components/form/inputs/file-crop';
import TextareaInput from '@common/components/form/inputs/textarea';

// Selectors
import { useAppSelector } from '@common/hooks/redux';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';

// Types
import { FileInProgress } from '@common/types/objects';
import { updateConversation } from '@modules/chat/api/update-conversation';
import { FullConversation } from '@modules/chat/types/objects';

interface ConversationFormOwnProps {
  conversation: FullConversation;
  onUpdateConversation?: (id: string, data: ConversationFormPayload) => void;
}

export type ConversationFormState = {
  name: string | null;
  description: string | null;
  group_img: FileInProgress | null;
};

export type ConversationFormPayload = Record<'name' | 'description' | 'group_img', string | null | undefined>;

type ReduxFormProps = InjectedFormProps<ConversationFormState, ConversationFormOwnProps, boolean>;
type ConversationFormProps = ConversationFormOwnProps & ReduxFormProps;

const ConversationForm = ({
  conversation, handleSubmit, submitting, dirty, initialize, onUpdateConversation,
}: ConversationFormProps) => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentOrgId);

  const onSubmit = handleSubmit(async (values) => {
    try {
      const payload = omitBy({
        name: values.name && values.name !== conversation.name ? values.name : undefined,
        description: values.description && values.description !== conversation.description ? values.description : undefined,
        group_img: values.group_img && values.group_img.path !== conversation.group_img ? values.group_img : undefined,
      }, isNil) as ConversationFormState;
      const responses = await updateConversation(orgId, conversation, payload);
      const newGroupImg = responses.find(
        (res) => res.data.group_img && res.data.group_img !== conversation.group_img,
      ) || null;
      onUpdateConversation?.(conversation.id, {
        ...payload,
        group_img: newGroupImg?.data.group_img || null,
      });
      initialize({
        ...values,
        group_img: null,
      });
    } catch (response: any) {
      Alert.forStatus(response.status_code, {
        warning: t('chat:conversation_form_warning_edit_name'),
        error: t('chat:conversation_form_error_edit_name'),
      });
    }
  });

  return (
    <form className="Form">
      <div className="ConversationForm__Image">
        <div
          className="ConversationForm__Image__Container"
          style={{ backgroundImage: `url(${conversation.group_img || '/static/images/group_chat_placeholder.png'}` }}
        >
          <Field
            name="group_img"
            component={({ input: { value, onChange } }: WrappedFieldProps) => {
              return value
                ? <FilePreview size={550} file={value} onRemove={() => onChange(null)} />
                : (
                  <FileCropInput name="group_img">
                    <Button type="white">
                      {t('chat:conversation_form_upload_new_image')}
                    </Button>
                  </FileCropInput>
                );
            }}
          />
        </div>
      </div>
      <Row>
        <Group>
          <TextInput name="name" placeholder={t('chat:conversation_form_name_input_placeholder')} />
        </Group>
        {submitting ? <Spinner /> : dirty && (
          <a className="ConversationForm__TitleForm__Save" onClick={onSubmit}>
            {t('chat:conversation_form_save')}
          </a>
        )}
      </Row>
      <Row>
        <Group>
          <TextareaInput
            name="description"
            placeholder={t('chat:conversation_form_description_input_placeholder')}
          />
        </Group>
      </Row>
    </form>
  );
};

export default reduxForm<ConversationFormState, ConversationFormOwnProps, boolean>({
  initialValues: {
    name: '',
    description: '',
    group_img: null,
  },
})(ConversationForm);
