import { Api } from '@common/services/api';

import type { User } from '@common/types/objects';
import type { Pagination } from '@common/components/list';
import type { APIResponsePrivateMessage } from '../types/objects';

export type APIResponseFetchMessages = {
  data: APIResponsePrivateMessage[];
  meta: {
    pagination: Pagination;
    related: {
      users: User[];
    };
  };
};

export const fetchMessages = async (
  organisationId: string, conversationId: string, cursor?: string | boolean | null,
) => {
  const request = await Api.get<APIResponseFetchMessages>(
    `/v3/organisations/${organisationId}/conversations/${conversationId}/messages?${Api.utils.toQuery({ cursor })}`,
  );
  return request;
};
