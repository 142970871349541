import Api from '@common/services/api';
import { APIResponseConversation } from '../types/objects';
import { User } from '@common/types/objects';

export type APIResponseNewConversation = APIResponseConversation & {
  participants: User[];
};

export type APIResponseCreateConversation = {
  data: APIResponseNewConversation;
  is_new: boolean;
};

interface CreateConversationPayload extends Record<string, unknown> {
  name?: string;
  participant_ids: string[];
  type: APIResponseConversation['conversation_type'];
}

export const createConversation = async (
  organisationId: string,
  payload: CreateConversationPayload,
) => {
  return Api.post<APIResponseCreateConversation>(`/v3/organisations/${organisationId}/conversations`, payload);
};
