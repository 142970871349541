import Api from '@common/services/api';

import type { Pagination } from '@common/components/list';
import type { User } from '@common/types/objects';
import type { APIResponseConversation } from '../types/objects';
import { EConversationFilters } from '../definitions';

export type APIResponseFetchConversations = {
  data: APIResponseConversation[];
  meta: {
    pagination: Pagination;
    archived_count: number;
    related: {
      users: User[];
    };
  };
};

interface FetchConversationsParams {
  organisationId: string;
  filter?: EConversationFilters;
  search?: string;
  nextCursor?: string | boolean | null;
  limit?: number;
}

// Todo: add some kind of caching mechanism
export const fetchConversations = async ({
  organisationId, filter, search, nextCursor = true, limit = 15,
}: FetchConversationsParams) => {
  const query = Api.utils.toQuery({
    limit,
    cursor: nextCursor || true,
    archived: filter === EConversationFilters.ARCHIVED,
    q: search,
  });
  return Api.get<APIResponseFetchConversations>(
    `/v3/organisations/${organisationId}/conversations?include=participants&${query}`,
  );
};
