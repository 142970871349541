import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Attachment from '@common/components/attachment';
import Dropdown from '@common/components/dropdown';
import Icon from '@common/components/icon';
import Confirm from '@common/components/confirm-button';

// Services
import * as AnalyticsService from '@common/services/analytics';

// Types
import { EFileTypes, FileTypes } from '@common/definitions';
import { Attachment as AttachmentType } from '@common/types/objects';
import { EEventNames } from '../../../../../client/analytics';


const shouldShowPreview = (attachment: AttachmentType) => (
  !!attachment?.file_type && ([EFileTypes.IMAGE, EFileTypes.GIF, EFileTypes.VIDEO] as FileTypes[]).includes(attachment.file_type)
);

interface MessageAttachmentProps {
  attachment: AttachmentType;
  timestamp: string;
  conversationId: string;
  showActions: boolean;
  canDelete: boolean;
  onRemove: () => void;
  setDropdownOpen: (open: boolean) => void;
  setModalOpen: (open: boolean) => void;
  setShowSettings: (open: boolean) => void;
}

const MessageAttachment = ({
  attachment, timestamp, conversationId, showActions, canDelete,
  onRemove, setDropdownOpen, setModalOpen, setShowSettings,
}: MessageAttachmentProps) => {
  const { t } = useTranslation();
  const isPreview = useMemo(() => shouldShowPreview(attachment), [attachment]);
  return (
    <div className="PrivateMessage__Container PrivateMessage__Container--preview" key={attachment.id}>
      <div className={`${isPreview ? 'tw-relative' : 'PrivateMessage__Inner'}`}>
        <Attachment
          key={attachment.id}
          item={attachment}
          maxHeight={300}
          label={timestamp}
          onOpen={() => AnalyticsService.track(EEventNames.OPENED_FILE_IN_CHAT, { conversationId })}
          includeLoader
        />
        {!isPreview && <small className="PrivateMessage__timestamp">{timestamp}</small>}
        {showActions && (
          <Dropdown
            alignRight
            onOpen={() => setDropdownOpen(true)}
            onClose={() => setDropdownOpen(false)}
            trigger={(
              <div className={`${isPreview ? 'PrivateMessage__Options--preview' : 'PrivateMessage__Options'}`}>
                <Icon type="more_vert" />
              </div>
            )}
          >
            {canDelete && (
              <Confirm
                title={t('chat:confirm_remove_message')}
                onEnter={() => setModalOpen(true)}
                onExit={() => {
                  setModalOpen(false);
                  setShowSettings(false);
                }}
                onConfirm={() => onRemove()}
              >
                <Dropdown.Item danger>
                  {t('chat:remove_message')}
                </Dropdown.Item>
              </Confirm>
            )}
          </Dropdown>
        )}
        {isPreview && <div className="PrivateMessage__AttachmentDivider" />}
      </div>
    </div>
  );
};

export default MessageAttachment;
