import React, { memo } from 'react';

import { Checkbox } from '@common/components/form/inputs/checkbox';
import ImageItemUser from '@common/components/image-item/user';
import { LoggedUser, User } from '@common/types/objects';

interface ParticipantsListUserComponentProps {
  item: User;
  loggedUser: LoggedUser;
  isGroup: boolean;
  checked: boolean;
  onClick: (value: boolean) => void;
  disabled?: boolean;
}

const ParticipantsListUserComponent = memo(({
  item, loggedUser, isGroup, checked, disabled = false, onClick,
}: ParticipantsListUserComponentProps) => {
  if (item.id === loggedUser.id) return null;

  const classNames = ['NewConversationModal__Item'];
  if (disabled) classNames.push('NewConversationModal__Item--disabled');

  return (
    <div
      className={classNames.join(' ')}
      onClick={!disabled ? () => onClick(!checked) : undefined}
    >
      <ImageItemUser item={item} />
      {isGroup && (
        <Checkbox
          size="large"
          value={checked}
          disabled={disabled}
          onChange={onClick}
        />
      )}
    </div>
  );
});

export default ParticipantsListUserComponent;
