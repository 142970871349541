import { useCallback, useEffect } from 'react';

// Services
import SocketService from '@common/services/socket';

// Utils
import { parseMessage } from '@modules/chat/utils/messages';

// Types
import { ESocketEvents } from '@modules/core/definitions';
import { APIResponseConversation, APIResponsePrivateMessage, PrivateMessage } from '../types/objects';
import { User } from '@common/types/objects';

type UsePusherMessagesListenerProps = {
  messages?: PrivateMessage[] | null;
  users: Record<string, User>;
  handlePusherNewMessage: (orgId: string, id?: string, message?: PrivateMessage, markAsSeen?: boolean) => void;
  updateMessage?: (
    messageId: string, propName: keyof PrivateMessage, value: PrivateMessage[keyof PrivateMessage], conversationId?: string,
  ) => void;
};

type CreateMessagePayload = {
  conversation: APIResponseConversation;
  data: APIResponsePrivateMessage;
};

type UpdateMessagePayload = {
  data: APIResponsePrivateMessage;
};

type RemoveMessagePayload = {
  conversation: APIResponseConversation;
  data: APIResponsePrivateMessage;
};

export const usePusherMessagesListener = ({
  messages, users, handlePusherNewMessage, updateMessage,
}: UsePusherMessagesListenerProps) => {
  // See https://linear.app/oneteam/issue/ONE-1698/receiving-new-chat-message-from-same-user-on-different-device-should
  const createMessageByMeHandler = useCallback((payload: CreateMessagePayload) => {
    if (!messages?.find((message) => message.id === payload.data.id) && users) {
      handlePusherNewMessage(
        payload.conversation.organisation_id,
        payload.conversation.id,
        parseMessage(payload.data, { users: Object.values(users) }),
        true,
      );
    }
  }, [messages, users, handlePusherNewMessage]);

  const createMessageHandler = useCallback((payload: CreateMessagePayload) => {
    handlePusherNewMessage(
      payload.conversation.organisation_id,
      payload.conversation.id,
      parseMessage(payload.data, { users: Object.values(users) }),
    );
  }, [handlePusherNewMessage, users]);

  const updateMessageHandler = useCallback((payload: UpdateMessagePayload) => {
    updateMessage?.(payload.data.id, 'reactions', payload.data.reactions);
  }, [updateMessage]);

  const removeMessageHandler = useCallback((payload: RemoveMessagePayload) => {
    updateMessage?.(payload.data.id, 'deleted_at', payload.data.deleted_at, payload.conversation.id);
  }, [updateMessage]);

  useEffect(() => {
    if (updateMessage) SocketService.registerEvent('me', ESocketEvents.PRIVATE_MESSAGE_REMOVED, removeMessageHandler);
  }, [removeMessageHandler, updateMessage]);

  useEffect(() => {
    if (updateMessage) SocketService.registerEvent('me', ESocketEvents.PRIVATE_MESSAGE_UPDATED, updateMessageHandler);
  }, [updateMessageHandler, updateMessage]);

  useEffect(() => {
    SocketService.registerEvent('me', ESocketEvents.PRIVATE_MESSAGE_CREATED, createMessageHandler, 'chat');
  }, [createMessageHandler, handlePusherNewMessage]);

  useEffect(() => {
    SocketService.registerEvent('me', ESocketEvents.PRIVATE_MESSAGE_CREATED_SENDER, createMessageByMeHandler);
  }, [createMessageByMeHandler, handlePusherNewMessage]);
};
