import React, { memo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';

// Components
import Badge from '@common/components/badge/badge';
import Icon from '@common/components/icon';
import { UserStatusBadge } from '@common/components/profile-image';

import ConversationImage from '../conversation-image';
import ConversationActions from '../conversation-actions';
import ConversationArchivedActions from '../conversation-archived-actions';
import MessageLast from '../message-last';

// Hooks
import { useAppSelector } from '@common/hooks/redux';

// Utils
import { createTimeStamp } from '@common/utils/create-timestamp';
import { combineClassNames } from '@common/utils/combineClassNames';

// Selectors
import { selected } from '@modules/core/selectors/logged-user';

// Types
import { FullConversation, PrivateMessage } from '@modules/chat/types/objects';
import { RouteParams } from '@modules/chat/definitions';
import { User, UserStatus } from '@common/types/objects';
import { ConversationFormPayload } from '@modules/chat/forms/conversation/conversation';
import { EStatus } from '@common/definitions';

interface ConversationItemProps {
  currentConversationId: string;
  item: FullConversation;
  basePath: string;
  usersStatus: Record<string, UserStatus | null>;
  onLeave: (id: string) => void;
  onOpenProfile: (userId: string) => void;
  onToggleArchive: (id: string, shouldArchive: boolean) => void;
  onToggleNotifications: (conversationId: string, is_muted: boolean) => void;
  onEditGroupAdmin: (conversationId: string, user: User, add: boolean) => void;
  onRemoveConversation: (conversationId: string) => void;
  onUpdateConversation: (id: string, data: ConversationFormPayload) => void;
  onEditParticipants: (
    conversationId: string, activities: PrivateMessage | PrivateMessage[], participants: User | User[],
  ) => void;
}

const ConversationItem: React.FC<ConversationItemProps> = memo(({
  currentConversationId, item, basePath, usersStatus,
  onOpenProfile, onToggleArchive, onToggleNotifications,
  onRemoveConversation, onEditGroupAdmin, onEditParticipants, onLeave, onUpdateConversation,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { filter } = useParams<RouteParams>();
  const loggedUser = useAppSelector(selected);

  const handleClick = useCallback(() => {
    history.push(filter ? `${basePath}/filter/${filter}/${item.id}` : `${basePath}/${item.id}`);
  }, [item.id, history, filter, basePath]);

  const status = item.participant?.id ? usersStatus[item.participant?.id] : null;

  return (
    <div
      className={combineClassNames('ConversationItem fs-exclude ImageItem ImageItem--large', {
        'ConversationItem--state-active': currentConversationId === item.id,
      })}
      role="button"
      onClick={handleClick}
    >
      {status && (
        <UserStatusBadge
          formattedStatus={status}
          profileImageSize={40}
        />
      )}
      <ConversationImage
        loggedUser={loggedUser}
        conversation={item}
        className={status ? 'hasStatus' : undefined}
      />
      <div className="tw-flex tw-flex-col tw-flex-1 tw-min-w-0">
        <h5 className="tw-flex tw-items-center tw-leading-6 tw-text-base tw-normal-case tw-m-0">
          <div className="tw-flex-[1] tw-items-center tw-overflow-hidden tw-text-ellipsis tw-whitespace-nowrap">
            {item.name || item.participant?.full_name}
            {item.is_muted && (
              <Icon
                type="notifications_off"
                color="grey"
                className="!tw-float-none !tw-w-4 !tw-h-4 tw-ml-1"
              />
            )}
          </div>
          <small>
            {createTimeStamp(item.last_message?.created_at || item.created_at)}
          </small>
        </h5>
        <div className="ConversationItem__Content">
          <MessageLast
            type={item.conversation_type}
            message={item.last_message}
          />
          {item.is_archived ? (
            <div className="ConversationItem__Archived">
              <Badge
                status={EStatus.ARCHIVED}
                text={t('chat:conversation_archived')}
              />
              <ConversationArchivedActions
                conversation={item}
                onUnarchive={() => onToggleArchive(item.id, false)}
                onRemoveConversation={onRemoveConversation}
              />
            </div>
          ) : ((
            <ConversationActions
              conversation={item}
              usersStatus={usersStatus}
              onOpenProfile={onOpenProfile}
              onToggleNotifications={onToggleNotifications}
              onArchive={() => onToggleArchive(item.id, true)}
              onEditGroupAdmin={onEditGroupAdmin}
              onEditParticipants={onEditParticipants}
              onRemoveConversation={onRemoveConversation}
              onLeave={onLeave}
              onUpdateConversation={onUpdateConversation}
            />
          ))}
        </div>
      </div>
    </div>
  );
});

export default ConversationItem;
