import { useCallback } from 'react';
import { User } from '@common/types/objects';

export const useToggleParticipants = (
  addedParticipants: User[], setAddedParticipants: (participants: User[]) => void,
) => useCallback(
  (user: User, selected: boolean) => {
    setAddedParticipants(selected
      ? [...addedParticipants, user]
      : addedParticipants.filter((u) => u.id !== user.id)
    );
  }, [addedParticipants]);
