import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Components
import Confirm from '@common/components/confirm-button';
import Dropdown from '@common/components/dropdown';
import { EmojiReactionPicker } from '@common/components/emoji-reaction-picker';
import { EmojiReactionsUserList } from '@common/components/emoji-reactions-user-list';
import Icon from '@common/components/icon';

// Types
import { AllowedEmoji } from '@common/types/objects';
import { PrivateMessage } from '@modules/chat/types/objects';

interface MessageActionsProps {
  message: PrivateMessage;
  conversationId: string;
  allowedEmojis: AllowedEmoji[];
  canDelete: boolean;
  setModalOpen: (open: boolean) => void;
  setShowSettings: (open: boolean) => void;
  onReply: (messageId: string) => void;
  onRemove: (messageId: string) => void;
  setDropdownOpen: (open: boolean) => void;
  onSetEmojiReaction: (messageId: string, emoji: AllowedEmoji, isSelected: boolean) => void;
}

const MessageActions = ({
  message, allowedEmojis, conversationId, canDelete,
  onReply, onRemove, setDropdownOpen, setModalOpen, setShowSettings, onSetEmojiReaction,
}: MessageActionsProps) => {
  const { t } = useTranslation();
  const [isEmojisModalOpen, setIsEmojiModalOpen] = useState(false);

  const handleAddNewReaction = useCallback((emoji: AllowedEmoji) => {
    // eslint-disable-next-line camelcase
    const isAlreadySelected = message.reactions.find(({ short_name, is_selected_by_user }) => (
      // eslint-disable-next-line camelcase
      short_name === emoji.short_name && is_selected_by_user
    ));

    onSetEmojiReaction(message.id, emoji, !isAlreadySelected);
  }, [onSetEmojiReaction, message.id, message.reactions]);

  return (
    <div className="PrivateMessage__OptionsOverlay">
      <EmojiReactionPicker
        allowedEmojis={allowedEmojis}
        onSelect={handleAddNewReaction}
      />

      <Dropdown
        alignRight
        className="PrivateMessage__OptionsDropdown"
        onOpen={() => setDropdownOpen(true)}
        onClose={() => setDropdownOpen(false)}
        trigger={(
          <div className="PrivateMessage__Options">
            <Icon type="more_vert" />
          </div>
        )}
      >
        <Dropdown.Item
          onClick={() => onReply(message.id)}
        >
          {t('chat:reply')}
        </Dropdown.Item>
        {message.reactions.length > 0 && (
          <EmojiReactionsUserList
            show={isEmojisModalOpen}
            onSetVisibility={setIsEmojiModalOpen}
            messageId={message.id}
            conversationId={conversationId}
            emojiReactions={message.reactions}
          >
            <Dropdown.Item>
              {t('chat:reactions')}
            </Dropdown.Item>
          </EmojiReactionsUserList>
        )}
        {canDelete && (
          <Confirm
            title={t('chat:confirm_remove_message')}
            onEnter={() => setModalOpen(true)}
            onConfirm={() => onRemove(message.id)}
            onExit={() => {
              setModalOpen(false);
              setShowSettings(false);
            }}
          >
            <Dropdown.Item danger>
              {t('chat:remove_message')}
            </Dropdown.Item>
          </Confirm>
        )}
      </Dropdown>
    </div>
  );
};

export default MessageActions;
