import { Api } from '@common/services/api';

import type { AllowedEmoji } from '@common/types/objects';
import type { APIResponsePrivateMessage } from '../types/objects';

export type APIResponseSetMessageEmoji = {
  data: APIResponsePrivateMessage;
};

export const setMessageEmoji = async (
  organisationId: string, conversationId: string, messageId: string, emoji: AllowedEmoji, remove: boolean = false,
) => {
  const { short_name } = emoji;
  if (remove) {
    return Api.delete<APIResponseSetMessageEmoji>(
      `/v3/organisations/${organisationId}/conversations/${conversationId}/messages/${messageId}/reactions/${short_name}`,
    );
  }
  return Api.post<APIResponseSetMessageEmoji>(
    `/v3/organisations/${organisationId}/conversations/${conversationId}/messages/${messageId}/reactions`,
    { short_name },
  );
};
