import Api from '@common/services/api';
import { APIResponsePrivateMessage } from '../types/objects';
import { EConversationTypes } from '../definitions';

export type UpdateAdminsResponse = {
  data: {
    id: string;
    name: string;
    description: string | null;
    conversation_type: typeof EConversationTypes[keyof typeof EConversationTypes];
    group_img: string | null;
    last_message: APIResponsePrivateMessage | null;
    organisation_id: string;
    last_activity_id: string;
    last_object_id: string;
    admin_ids: string[];
    participant_ids: string[];
    created_at: string;
    updated_at: string;
  };
};

export const updateAdmins = (organisationId: string, conversationId: string, adminIds: string[], add: boolean) => {
  return Api.put<UpdateAdminsResponse>(`/v3/organisations/${organisationId}/conversations/${conversationId}/admins`, {
    [add ? 'add' : 'remove']: adminIds,
  });
};
