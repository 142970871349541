import moment from 'moment';

import { EConversationActivityTypes, EMessageTypes } from '../definitions';
import { PrivateMessage } from '../types/objects';

let tempId = 0;

export const createActivity = (
  type: typeof EConversationActivityTypes[keyof typeof EConversationActivityTypes],
  metaData: PrivateMessage['meta_data'],
) => ({
  type: EMessageTypes.ACTIVITY,
  activity_type: type,
  source_type: null,
  object_id: `o${tempId++}`,
  id: `a${tempId++}`,
  created_at: moment().toISOString(),
  meta_data: metaData,
  text: '',
  seen: false,
  user_id: null,
  user: null,
  parent_message: undefined,
  reactions: [],
  attachments: [],
}) as PrivateMessage;
