import React from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmButton } from '@common/components/confirm-button';
import Dropdown from '../../../../common/components/dropdown';
import Icon from '../../../../common/components/icon';
import { FullConversation } from '@modules/chat/types/objects';

interface ConversationArchivedActionsProps {
  conversation: FullConversation;
  onUnarchive: (conversationId: string) => void;
  onRemoveConversation: (conversationId: string) => void;
}

const ConversationArchivedActions = ({
  conversation, onUnarchive, onRemoveConversation,
}: ConversationArchivedActionsProps) => {
  const { t } = useTranslation();

  return (
    <Dropdown
      alignRight
      toggle={(
        <Icon
          className="ConversationActions"
          type="more_vert"
        />
      )}
    >
      <Dropdown.Item onClick={() => onUnarchive(conversation.id)}>
        {t('chat:conversation_unarchive')}
      </Dropdown.Item>

      <ConfirmButton
        title={t('chat:conversation_remove_confirm_title')}
        onConfirm={() => onRemoveConversation(conversation.id)}
      >
        <Dropdown.Item danger>
          {t('chat:remove_conversation')}
        </Dropdown.Item>
      </ConfirmButton>
    </Dropdown>
  );
};

export default ConversationArchivedActions;
