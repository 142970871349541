import * as React from 'react';
import { useTranslation } from 'react-i18next';

import AsyncList from '../../../../common/components/list/async';
import SectionHeader from '../../../../common/components/section-header';
import UserItem from './user';

import { LoggedUser, User } from '@common/types/objects';

type Response = {
  data: Array<User>;
  meta: { pagination: Object };
};

type Data = {
  label: string;
  response: Response;
  onFetch: () => Promise<Response>;
};

interface ParticipantsListSectionComponentProps {
  item: Data;
  loggedUser: LoggedUser;
  isGroup: boolean;
  participants?: Array<User>;
  onToggle: (item: User, selected: boolean) => void;
  onSelect: (item: User) => void;
  isDisabled?: (item: User) => boolean;
}

const ParticipantsListSectionComponent = ({
  item, loggedUser, isGroup, participants = [],
  onToggle, onSelect, isDisabled,
}: ParticipantsListSectionComponentProps) => {
  const { t } = useTranslation();
  const { label, response, onFetch } = item;
  return (
    <>
      <SectionHeader title={label} />
      <AsyncList
        disableInitialFetch
        data={{
          onFetch,
          cache: {
            items: response.data,
            pagination: response.meta.pagination,
          },
          useCursor: true,
        }}
        renderRow={UserItem}
        rowProps={(user: User) => ({
          isGroup,
          loggedUser,
          disabled: isDisabled ? isDisabled(user) : false,
          checked: participants.some((participant) => user.id === participant.id),
          onClick: (selected: boolean) => (isGroup ? onToggle(user, selected) : onSelect(user)),
        })}
        placeholder={t('chat:participants_list_section_placeholder')}
        ShowMoreComponent={({ onShowMore }) => (
          <div
            className="NewConversationModal__Item NewConversationModal__Item--more"
            onClick={onShowMore}
          >
            {t('chat:participants_list_section_load_more')}
          </div>
        )}
      />
    </>
  );
};

export default ParticipantsListSectionComponent;
