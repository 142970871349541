import React, {
  RefObject, useEffect, useState, useCallback, useMemo,
} from 'react';
import { InjectedFormProps, reduxForm } from 'redux-form';
import { useTranslation } from 'react-i18next';

import FileInput from '@common/components/form/inputs/file';
import GifInput from '@common/components/form/inputs/gif/gif';
import Permission from '@common/components/permission';
import TextareaInput from '@common/components/form/inputs/textarea';
import Tooltip from '@common/components/tooltip';
import Icon from '@common/components/icon';
import { Button } from '@common/components/button';
import { Messages, ErrorType } from '@common/components/form/message';
import FilePreview from '@common/components/file-preview/file';
import ImagePreview from '@common/components/file-preview/image';
import { Row } from '@common/components/form';

// Hooks
import { useFormValues } from '@common/hooks/form-hook';
import { useFileFromClipboard } from '@common/components/form/inputs/file/hooks';

// Utils
import { combineClassNames } from '@common/utils/combineClassNames';
import { ALL_ACCEPT } from '@common/components/form/inputs/file/utils';
import { canPreviewAttachment } from '@common/utils/file';
import * as fileUtil from '@common/utils/file';

// Types
import { Attachment, FileInProgress, FileInputValue } from '@common/types/objects';
import { PrivateMessage } from '@modules/chat/types/objects';
import { EComponentTypes } from '@common/definitions';

export type PostMessageFormData = {
  text: string;
  attachments: FileInputValue[];
  message_id: string | null;
  gif?: Attachment;
};

type OwnProps = {
  disabled?: boolean;
  replyTo?: PrivateMessage;
  inputRef: RefObject<HTMLTextAreaElement>;
  handleReplyToMessage: (messageId?: string) => void;
  onAddMessage: (message: PostMessageFormData) => Promise<void>;
};

type Props = InjectedFormProps<PostMessageFormData, OwnProps, boolean> & OwnProps;

const MAX_AMOUNT_OF_FILES = 10;

const PostMessage = ({
  inputRef, form, submitting, disabled, invalid, replyTo,
  change, reset, handleSubmit, handleReplyToMessage, onAddMessage,
}: Props) => {
  const { t } = useTranslation();
  const { attachments, gif } = useFormValues<PostMessageFormData>(form);
  const [rejectedFiles, setRejectedFiles] = useState<ErrorType[]>([]);

  const onAttachmentUpdate = (file?: FileInputValue, index?: number) => change(`attachments[${index}]`, file);
  const { onChange: handleAddImageFromClipboard } = useFileFromClipboard(
    attachments, MAX_AMOUNT_OF_FILES, onAttachmentUpdate, setRejectedFiles,
  );

  useEffect(() => change('message_id', replyTo?.id || null), [replyTo]);

  const submitForm = useCallback(async (values: PostMessageFormData) => {
    onAddMessage(values);
    reset();
  }, [onAddMessage, handleReplyToMessage, inputRef, reset]);

  const handleRemoveAttachment = useCallback((index: number) => {
    const attachment = attachments[index];
    if (attachment) {
      if (attachment?.processing) (attachment as FileInProgress).cancelPromise?.();
      attachments.splice(index, 1);
      change('attachments', attachments);
    }
  }, [attachments, change]);

  const { images, documents } = useMemo(() => {
    return attachments.reduce((acc, attachment) => {
      if (canPreviewAttachment(attachment)) acc.images.push(attachment);
      else acc.documents.push(attachment);
      return acc;
    }, { images: [] as FileInputValue[], documents: [] as FileInputValue[] });
  }, [attachments]);

  return (
    <>
      {replyTo && (
        <div className="PostPrivateMessage__Reply">
          <div className="PostPrivateMessage__Reply__Quote">
            <b>{replyTo.user?.full_name}</b>
            {replyTo.text}
          </div>
          <Icon
            type="close"
            onClick={() => handleReplyToMessage()}
          />
        </div>
      )}
      <form
        onSubmit={handleSubmit(submitForm)}
        className={combineClassNames('Form PostPrivateMessage fs-mask', {
          'PostPrivateMessage--invalid': !!rejectedFiles.length,
        })}
      >
        {rejectedFiles?.length > 0 && <Messages errors={rejectedFiles} />}
        {gif && (
          <div className="PostPrivateMessage__Attachments">
            <ImagePreview
              key={gif.id}
              file={gif}
              size={70}
              onRemove={() => change('gif', null)}
            />
          </div>
        )}
        {images.length > 0 && (
          <div className="PostPrivateMessage__Attachments">
            {images.map((attachment) => (
              <ImagePreview
                key={attachment.id || attachment.file_name}
                file={attachment}
                size={70}
                onRemove={() => handleRemoveAttachment(attachment.index!)}
              />
            ))}
            <FileInput
              multiple={MAX_AMOUNT_OF_FILES}
              name="attachments"
              accept="image/*,video/*"
              onError={setRejectedFiles}
            >
              <Icon type="add" />
            </FileInput>
          </div>
        )}
        {documents.length > 0 && (
          <div className="PostPrivateMessage__Documents">
            {documents.map((attachment) => (
              <FilePreview
                key={attachment.id || attachment.file_name}
                file={attachment}
                onRemove={() => handleRemoveAttachment(attachment.index!)}
              />
            ))}
          </div>
        )}
        <Row className="PostPrivateMessage__Actions">
          <FileInput
            multiple={MAX_AMOUNT_OF_FILES}
            disabled={!!gif}
            name="attachments"
            accept="image/*, video/*"
            onError={setRejectedFiles}
          >
            <Tooltip title={t('chat:post_message_attachment_photo_video')}>
              <Icon type="image__filled" className="PostPrivateMessage__Action" />
            </Tooltip>
          </FileInput>
          <FileInput
            multiple={MAX_AMOUNT_OF_FILES}
            disabled={!!gif}
            name="attachments"
            accept={ALL_ACCEPT}
            onError={setRejectedFiles}
          >
            <Tooltip title={t('chat:post_message_attachment_document')}>
              <Icon type="document__filled" className="PostPrivateMessage__Action" />
            </Tooltip>
          </FileInput>
          <Permission component={EComponentTypes.GIPHY}>
            <GifInput name="gif" disabled={!!gif || attachments.length > 0}>
              <Icon type="gif" className="PostPrivateMessage__Action" />
            </GifInput>
          </Permission>
          <div className="PostPrivateMessage__Input">
            <TextareaInput
              ref={inputRef}
              enableEmojis
              disableValidationFeedback
              name="text"
              disabled={submitting}
              placeholder={t('chat:post_message_form_text_placeholder')}
              onEnter={handleSubmit(submitForm)}
              onPaste={handleAddImageFromClipboard}
            />
          </div>
          <Button
            type="primary"
            buttonType="submit"
            icon="send"
            isLoading={submitting}
            disabled={disabled || invalid}
          />
        </Row>
      </form>
    </>
  );
};

export default reduxForm<PostMessageFormData, OwnProps, boolean>({
  destroyOnUnmount: false,
  initialValues: {
    text: '',
    attachments: [],
    message_id: null,
  },
  validate: (values: PostMessageFormData) => {
    const errors: Record<string, boolean> = {};
    if (!values?.text && values?.attachments?.length === 0 && !values?.gif) errors.text = true;
    if (fileUtil.isProcessingAttachments(values?.attachments || [])) errors.attachments = true;
    return errors;
  },
})(PostMessage);
