import React from 'react';

import Icon from '@common/components/icon';
import UserStatusPreview from '@common/components/user-status/user-status-preview';
import ConversationImage from '../conversation-image';

import { UserStatus } from '@common/types/objects';
import { FullConversation } from '@modules/chat/types/objects';
import { useAppSelector } from '@common/hooks/redux/use-app-selector';
import * as userSelector from '../../../core/selectors/logged-user';

interface ConversationHeaderProps {
  conversation: FullConversation;
  isGroup?: boolean;
  participantStatus?: UserStatus;
  onClick?: () => void;
}

const ConversationHeader = ({
  conversation, participantStatus, isGroup = true, onClick,
}: ConversationHeaderProps) => {
  const loggedUser = useAppSelector(userSelector.selected);
  return (
    <div
      className={`ImageItem ImageItem--large${conversation.has_left || !isGroup ? ' disabled' : ''}`}
      onClick={onClick}
    >
      <ConversationImage
        loggedUser={loggedUser}
        conversation={conversation}
      />
      <div className="ImageItem__info conversationHead">
        <h5 className="ImageItem__info__name">
          {conversation.name || conversation.participant?.full_name}
          {conversation.is_muted && <Icon type="notifications_off" />}
        </h5>
        {participantStatus && <UserStatusPreview status={participantStatus} />}
      </div>
    </div>
  );
};

export default ConversationHeader;
