import React from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { createTextForActivity } from '../activity/activity';

// Selectors
import { selected } from '@modules/core/selectors/logged-user';

// Types
import { PrivateMessage } from '@modules/chat/types/objects';
import { EConversationTypes, EMessageTypes } from '@modules/chat/definitions';
import { useAppSelector } from '@common/hooks/redux';

interface MessageLastOwnProps {
  // item: FullConversation;
  type: typeof EConversationTypes[keyof typeof EConversationTypes];
  message?: PrivateMessage | null;
}

const MessageLast = ({ type, message }: MessageLastOwnProps) => {
  const { t } = useTranslation();
  const loggedUser = useAppSelector(selected);

  if (!message || message.deleted_at) {
    return (
      <div className="ConversationItem__LastMessage">
        {!message && (<small>{t('chat:conversation_item_no_messages')}</small>)}
        {message?.deleted_at && (<small>{t('chat:message_removed')}</small>)}
      </div>
    );
  }

  const attachment = message.attachments?.[0] || null;
  const isActivity = message.type !== EMessageTypes.MESSAGE;
  const isGroup = type === EConversationTypes.GROUP &&
    message.user && message.type === EMessageTypes.MESSAGE;

  return (
    <div className="ConversationItem__LastMessage">
      {!message.seen && <span className="ConversationItem__Unread" />}
      {isGroup && (
        <span className="ConversationItem__LastMessage__Creator">
          {message?.user?.id === loggedUser.id ?
            `${t('chat:conversation_participant_yourself')}: ` :
            `${message?.user?.first_name}: `}
        </span>
      )}
      {attachment ? `(${t('chat:conversation_item_attachment_prefix', { context: attachment.file_type })}) ` : ''}
      {message?.text}
      {isActivity && <Trans {...createTextForActivity(t, message!, loggedUser.id)} />}
    </div>
  );
};

export default MessageLast;
