import React, {
  useState, PropsWithChildren, useCallback, cloneElement, ReactElement,
} from 'react';
import { useTranslation } from 'react-i18next';
import { sortBy } from 'lodash';
import moment from 'moment';

// Components
import { Button } from '@common/components/button';
import ImageItem from '@common/components/image-item';
import List from '@common/components/list';
import Modal from '@common/components/modal';
import Confirm from '@common/components/confirm-button';
import SectionHeader from '@common/components/section-header';
// Chat components
import Form from '../conversation';
import ParticipantsList from '../../components/participants-list';
import ParticipantActions from '../../components/participant-actions';

// Hooks
import { useToggleParticipants } from '@modules/chat/hooks/use-toggle-participants';
import { useAppSelector } from '@common/hooks/redux/use-app-selector';

// Utils
import { createActivity } from '@modules/chat/utils/activity';
import { parseMessage } from '@modules/chat/utils/messages';

// Selectors
import * as userSelector from '../../../core/selectors/logged-user';
import { getCurrentOrgId } from '@modules/organisation/selectors/organisation';

// API
import { updateParticipants } from '@modules/chat/api/update-participants';

// Types
import type { FullConversation, PrivateMessage } from '@modules/chat/types/objects';
import type { User, UserStatus } from '@common/types/objects';
import type { ConversationFormPayload } from '../conversation/conversation';
import { EConversationActivityTypes } from '@modules/chat/definitions';

interface ConversationFormProps extends PropsWithChildren<{}> {
  conversation: FullConversation;
  onlyParticipants?: boolean;
  usersStatus: Record<string, UserStatus | null>;
  onUpdateConversation?: (id: string, data: ConversationFormPayload) => void;
  onEditGroupAdmin?: (conversationId: string, user: User, add: boolean) => void;
  onOpenProfile?: (userId: string) => void;
  onLeave?: (id: string) => void;
  onEditParticipants?: (
    conversationId: string, activities: PrivateMessage | PrivateMessage[], participants: User | User[],
  ) => void;
}

const ConversationForm = ({
  children, onlyParticipants = false, conversation, usersStatus,
  onOpenProfile, onEditGroupAdmin, onLeave, onEditParticipants, onUpdateConversation,
}: ConversationFormProps) => {
  const { t } = useTranslation();
  const loggedUser = useAppSelector(userSelector.selected);
  const orgId = useAppSelector(getCurrentOrgId);

  const [isAddingParticipants, setIsAddingParticipants] = useState(onlyParticipants || false);
  const [addedParticipants, setAddedParticipants] = useState<User[]>([]);
  const [submitting, setSubmitting] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const handleToggleGroupParticipant = useToggleParticipants(addedParticipants, setAddedParticipants);

  const onRemove = useCallback(async (participant: User) => {
    setSubmitting(true);
    const response = await updateParticipants(orgId, conversation.id, participant);
    if (response.data?.last_message) {
      const activity = parseMessage(response.data.last_message!, { users: [participant, loggedUser] });
      onEditParticipants?.(conversation.id, activity, participant);
    }
    setSubmitting(false);
  }, [conversation.id, onEditParticipants]);

  const handleSaveParticipants = useCallback(async () => {
    setSubmitting(true);
    await updateParticipants(orgId, conversation.id, addedParticipants);
    if (onlyParticipants) setIsVisible(false);
    const activities = addedParticipants.map((participant) => createActivity(EConversationActivityTypes.USER_ADDED, {
      actor: loggedUser, actor_id: loggedUser.id, user: participant, user_id: participant.id,
    }));
    onEditParticipants?.(conversation.id, activities, addedParticipants);
    setIsAddingParticipants(onlyParticipants || false);
    setAddedParticipants([]);
    setSubmitting(false);
  }, [
    conversation.id, addedParticipants, onlyParticipants, orgId,
    setIsVisible, setIsAddingParticipants, setAddedParticipants, setSubmitting,
  ]);

  const handleLeave = useCallback(() => {
    if (onLeave) onLeave(conversation.id);
    setIsVisible(false);
  }, [conversation.id, onLeave, setIsVisible]);

  return (
    <Modal
      fullWidthContent
      show={isVisible}
      onClose={() => setIsVisible(false)}
      list={!isAddingParticipants}
      size="small"
      title={t('chat:conversation_group_information')}
      className="NewConversationForm modal--color-grey"
      content={isAddingParticipants ? (
        <ParticipantsList
          isGroup
          delay={onlyParticipants}
          participants={[...conversation.participants, ...addedParticipants]}
          loggedUser={loggedUser}
          onToggle={handleToggleGroupParticipant}
          isDisabled={(item) => !!conversation.participants.find((p) => p.id === item.id)}
        />
      ) : (
        <>
          {conversation.is_admin ? ( // Editing group name
            <Form
              form={`conversation-${conversation.id}`}
              conversation={conversation}
              onUpdateConversation={onUpdateConversation}
              initialValues={{
                name: conversation.name || '',
                description: conversation.description,
              }}
            />
          ) : (
            <>
              <div className="ConversationForm__Image">
                <div className="ConversationForm__Image__Container">
                  <img src={conversation.group_img || '/static/images/group_chat_placeholder.png'} alt="Group chat" />
                </div>
              </div>
              <div className="ConversationForm__Title">
                {conversation.name || (
                  <div className="ConversationForm__Title__Placeholder">
                    {t('chat:conversation_form_title_placeholder')}
                  </div>
                )}
              </div>
              {conversation.description && (
                <div className="ConversationForm__Description">{conversation.description}</div>
              )}
            </>
          )}
          {conversation.admins && conversation.admins.length > 0 && (
            <>
              <SectionHeader
                title={t('chat:conversation_form_admins', { count: conversation.admins.length })}
              />
              <List
                items={sortBy(conversation.admins, 'full_name')}
                renderRow={ParticipantActions}
                rowProps={{
                  conversation, usersStatus, onOpenProfile, onEditGroupAdmin, onRemove
                }}
              />
            </>
          )}
          <SectionHeader
            title={t('chat:conversation_form_participants_count', { count: conversation.participants.length })}
          />
          <List
            items={sortBy(conversation.participants, 'full_name')}
            header={conversation.is_admin && (
              <ImageItem
                className="NewConversationModal__Item"
                icon="group_add"
                name={t('chat:conversation_form_add_colleagues')}
                onClick={() => setIsAddingParticipants(true)}
              />
            )}
            renderRow={ParticipantActions}
            rowProps={{
              conversation, usersStatus, onOpenProfile, onEditGroupAdmin, onRemove
            }}
          />
          {onLeave && (
            <Confirm
              title={t('chat:conversation_form_confirm_leave_group')}
              description={conversation.is_admin && conversation.admin_ids.length === 1
                ? t('chat:conversation_form_confirm_leave_group_description_admin')
                : t('chat:conversation_form_confirm_leave_group_description')}
              onConfirm={handleLeave}
            >
              <Button type="white" size="fill" icon="logout">
                {t('chat:conversation_form_leave_group')}
              </Button>
            </Confirm>
          )}
          {conversation.creator && (
            <div className="NewConversationModal__Item NewConversationModal__Item--standalone">
              <small>
                {t('chat:conversation_form_created_at', {
                  creator: conversation.creator.full_name,
                  createdAt: moment(conversation.created_at).format('DD-MM-YYYY HH:mm'),
                })}
              </small>
            </div>
          )}
        </>
      )}
      onBack={() => (!onlyParticipants && setIsAddingParticipants(false)) || undefined}
      footer={isAddingParticipants && (
        <Button
          type="primary"
          onClick={handleSaveParticipants}
          isLoading={submitting}
        >
          {t('chat:conversation_form_add_participant')}
        </Button>
      )}
    >
      {children && cloneElement(children as ReactElement, {
        onClick: () => setIsVisible(!isVisible),
      })}
    </Modal>
  );
};

export default ConversationForm;
