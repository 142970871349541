import { orderBy } from 'lodash';

// Utils
import { parseMessage } from './messages';

// Types
import type { APIResponseFetchConversations } from '../api/fetch-conversations';
import type { APIResponseConversation, FullConversation } from '../types/objects';
import type { User } from '@common/types/objects';
import type { APIResponseNewConversation } from '../api/create-conversation';
import { EConversationTypes } from '../definitions';

export const parseConversation = (
  conversation: APIResponseConversation,
  related: APIResponseFetchConversations['meta']['related'],
  loggedUserId: string,
): FullConversation => {
  // Get the recipient (other participant) in a private conversation
  let participant: User | null = null;
  const creator = related?.users.find((user) => user.id === conversation.user_id);
  if (conversation.conversation_type === EConversationTypes.PRIVATE) {
    const participantId = conversation.participant_ids.filter((id) => id !== conversation.user_id);
    if (participantId.length) {
      participant = related?.users.find((user) => user.id === participantId[0]) || null;
    }
  }

  return {
    ...conversation,
    is_admin: conversation.admin_ids.includes(loggedUserId),
    admins: related?.users.filter((user) => conversation.admin_ids.includes(user.id)) || [],
    participants: related?.users.filter((user) => conversation.participant_ids.includes(user.id)) || [],
    last_message: conversation.last_message ? parseMessage(conversation.last_message, related) : null,
    creator,
    participant,
  };
};

export const parseNewConversation = (
  conversation: APIResponseNewConversation,
  creator: User,
  loggedUserId: string,
): FullConversation => {
  const participant = conversation.participants.find((p) => p.id !== creator.id) || null;
  return {
    ...conversation,
    creator,
    participant,
    is_admin: conversation.admin_ids.includes(loggedUserId),
    admins: [creator],
    last_message: conversation.last_message ? parseMessage(conversation.last_message, { users: [creator] }) : null,
  };
};

export const parseAndSortConversations = (
  conversations: APIResponseConversation[],
  related: APIResponseFetchConversations['meta']['related'],
  loggedUserId: string,
): FullConversation[] => {
  return sortConversations(conversations.map(
    (conversation) => parseConversation(conversation, related, loggedUserId))
  );
};

export const sortConversations = (conversations: FullConversation[]): FullConversation[] => {
  return orderBy(conversations, (c) => new Date(c.last_message?.created_at || c.created_at).getTime(), ['desc']);
};
